import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { categorySelectors } from "../../store/categories/categories.selector";
import { TopProductsFilter } from "../../models/enums/top-products-filters.enum";
import { ProductCard } from "../Products/List/ProductCard/ProductCard";
import { bl } from "../../BL/BL";
import { ProductViewModel } from "../../models/types/product-view-model.type";
import { productDtoToViewModeladapter } from "../../models/adapters/product-dto-to-ciew-model.adapter";
import ContentLoader from "react-content-loader";
import leatherImg from '../../local/png-webp/main-page/leather.png';
import salesImg from '../../local/png-webp/main-page/sales.png';
import catalogImg from '../../local/png-webp/main-page/catalog.png';
import partnersImg from '../../local/png-webp/main-page/partners.png';

import { ReactComponent as CaseSvg } from '../../local/svg/main-page/case.svg';
import { ReactComponent as BeltSvg } from '../../local/svg/main-page/belt.svg';
import { ReactComponent as BannerLinkArrowSvg } from '../../local/svg/main-page/banner-link-arrow.svg';

import './MainPage.scss';
import { LoadingStatus } from "../../models/enums/loading-status.enum";
import { Link } from "react-router-dom";
import { CategoryDTO } from "../../models/types/category-dto.type";
import { ProductsActions } from "../../store/products/products.slice";
import { useAppDispatch } from "../../store/store.hooks";
import { DropDown } from "./DropDown/DropDown";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BannerDTO } from "../../models/types/banner-dto.type";

export const MainPage:FC = () => {
    const dispatch = useAppDispatch();

    const parentCategories = useSelector(categorySelectors.selectProductParentCategories);
    const categoriesLoadingStatus = useSelector(categorySelectors.selectLoadingStatus);

    const [width, setWidth] = useState(window.innerWidth);

    const [topProductsFilterStatus, setTopProductsFilterStatus] = useState<TopProductsFilter>(TopProductsFilter.TopSellers);
    const [topProducts, setTopProducts] = useState<ProductViewModel[]>([] as ProductViewModel[]);
    const [banners, setbanners] = useState<BannerDTO[]>([]);
    useEffect(() => {
        bl.fetchBanners().then(bannersDto => setbanners(bannersDto));
        bl.fetchTopProductsByOrder(topProductsFilterStatus).then(products => setTopProducts(products.map(product => productDtoToViewModeladapter.convertForwardToBackwardEntity(product))));
    }, [topProductsFilterStatus]);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
      }
    
    useEffect(() => {
        // component is mounted and window is available
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        // unsubscribe from the event on component unmount
        return () => window.removeEventListener('resize', handleWindowResize);
      }, []);

    /*const setFilterCategory = (category: CategoryDTO) => {
        dispatch(ProductsActions.clearProductsList());
        dispatch(ProductsActions.setProductParentCategory(category));
        dispatch(ProductsActions.setReloadStatus(true));
    };*/

    const changeTopProductsFilter = (filter: TopProductsFilter) => {
        setTopProducts([]);
        setTopProductsFilterStatus(filter);
        dispatch(ProductsActions.setReloadStatus(true));

    };

    const setDefaultFilters = () => {
        dispatch(ProductsActions.setDefaultFilter());
        dispatch(ProductsActions.setReloadStatus(true));
    };

    return(
        <section className="main-page__container">
            <Carousel selectedItem={banners.length - 1} transitionTime={900} autoPlay={true} infiniteLoop={true} interval={5000} className="main-page__banner-container">
                {banners.map(banner => 
                    <div key={banner.id} className="main-page__banner-element">
                        { width >= 1100 ? <img src={banner.src} className="main-page__banner-img main-page__banner-img-desc" />:
                        <img src={banner.mobileSrc} className="main-page__banner-img main-page__banner-img-mob" />
                        }
                        <div className="main-page__banner-info" >   
                            <h4 className="main-page__banner-title">
                                {banner.title}
                            </h4>
                            <p className="main-page__banner-description" dangerouslySetInnerHTML={{__html: banner.description}} />
                            <Link className="main-page__banner-link" onClick={() => setDefaultFilters()} to="/products">Перейти в каталог <BannerLinkArrowSvg /></Link>
                        </div>
                    </div>
                )}
            </Carousel>
            <div className="main-page__category-container">
                {/*
                    parentCategories.map(category => <Link onClick={() => setFilterCategory(category)} key={category.id} to='/products' className="main-page__category-element">
                        <h2 className="main-page__category-heading">
                            {category.name}
                        </h2>
                        <img className="main-page__category-img" src={category.image.src} />
                    </Link>)*/
                    
                }
                <Link /*onClick={() => setFilterCategory(category)}*/  to='/sales' className="main-page__category-element">
                        <h2 className="main-page__category-heading">
                            Акции
                        </h2>
                        <img className="main-page__category-img" src={salesImg} />
                </Link>
                <Link /*onClick={() => setFilterCategory(category)}*/  to='/products' className="main-page__category-element">
                        <h2 className="main-page__category-heading">
                            Каталог
                        </h2>
                        <img className="main-page__category-img" src={catalogImg} />
                </Link>
                <Link /*onClick={() => setFilterCategory(category)}*/  to='/partners' className="main-page__category-element">
                        <h2 className="main-page__category-heading">
                            Оптовым клиентам
                        </h2>
                        <img className="main-page__category-img" src={partnersImg} />
                </Link>
                {/*
                    categoriesLoadingStatus !== LoadingStatus.Fullfiled ? 
                    <ContentLoader
                    className="catalog__content-loader"
                    speed={1}
                    width={400}
                    height={400}
                    backgroundColor="#6b6b6b1c"
                    foregroundColor="#6b6b6b3b"
                    >
                        <rect x="20" y="30" rx="8" ry="8" width="360" height="20" />
                        <circle cx="200" cy="200" r="130" />
                    </ContentLoader> : null*/
                }
            </div>
            <div className="main-page__top-products-container">
                <div className="main-page__top-products-filter-container">
                    <p className={`main-page__top-products-filter-element main-page__top-products-filter-element-${topProductsFilterStatus === TopProductsFilter.TopSellers ? true : false}`} onClick={() => changeTopProductsFilter(TopProductsFilter.TopSellers)}>
                        ХИТ
                    </p>
                    <p className={`main-page__top-products-filter-element main-page__top-products-filter-element-${topProductsFilterStatus === TopProductsFilter.Newest ? true : false}`} onClick={() => changeTopProductsFilter(TopProductsFilter.Newest)}>
                        Новинка
                    </p>
                </div>
                <div className="main-page__top-products-cards-container">
                    {
                        topProducts.length !==0 ? topProducts.map(product => <ProductCard key={product.id} {...product} />) : 
                        <ContentLoader
                        className="catalog__content-loader"
                        speed={1}
                        width={250}
                        height={400}
                        backgroundColor="#6b6b6b1c"
                        foregroundColor="#6b6b6b3b"
                        >
                            <circle cx="125" cy="110" r="80" />
                            <rect x="25" y="230" rx="8" ry="8" width="300" height="20" />
                            <rect x="25" y="270" rx="8" ry="8" width="100" height="25" />
                        </ContentLoader>
                    }
                </div>
            </div>
            <div className="main-page__info-container">
                <div className="main-page__about-container">
                    <img className="main-page__info-img" src={leatherImg} />
                    <div className="main-page__info-details">
                        <h2 className="main-page__info-heading">
                            О компании
                        </h2>
                        <p className="main-page__info-text">
                            Компания Rembay, основанная в 2008 году, за десять лет успешной работы зарекомендовала себя в качестве поставщика действительно качественных товаров. 
                        </p>
                        <p className="main-page__info-text">
                            С самого начала мы преследовали высшие стандарты качества и долговечности. Каждый ремень, каждая сумка,
                            каждый аксессуар должен быть не просто предметом повседневного использования, а произведением искусства, способным подчеркнуть индивидуальность своего владельца.
                        </p>
                    </div>
                </div>
                <CaseSvg className="main-page__info-svg main-page__info-svg-left" />
                <BeltSvg className="main-page__info-svg main-page__info-svg-right" />
                <div className="main-page__questions-container">
                    <h2 className="main-page__questions-heading">
                        Часто задаваемые вопросы
                    </h2>
                    <DropDown question="Прессованная ли в изделиях кожа ?" answer="Нет , у нас только цельнокроеная кожа."/>
                    <DropDown question="Как узнать ширину ремня ?" answer="В артикуле вторая цифра обозначает ширину ремня в мм, например : 21-30 (21 это порядковый номер, 30 -ширина изделия в мм)."/>
                    <DropDown question="Срок доставки ?" answer="По РБ доставка два рабочих дня"/>
                </div>
            </div>
        </section>
    );
}