import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ShippingCartAdapter } from "./shipping-cart.slice";

const shippingCartState = (state:RootState) => state.shippingCart;

const ShippingCartEntitySelectors = ShippingCartAdapter.getSelectors(shippingCartState);

const selectShippingCart = createSelector(ShippingCartEntitySelectors.selectAll, products => products);

const selectShippingCartFullQuantity = createSelector(ShippingCartEntitySelectors.selectAll, products => {
    let accumulator: number = 0;
    products.forEach(product => accumulator += product.quantity);
    return accumulator;
});

const selectShippingCartFullQPrice = createSelector(ShippingCartEntitySelectors.selectAll, products => {
    let accumulator: number = 0;
    products.forEach(product => accumulator += Number(product.product.price)*product.quantity);
    return accumulator;
});

export const shippingCartSelectors = {
    selectShippingCart,
    selectShippingCartFullQuantity,
    selectShippingCartFullQPrice
}