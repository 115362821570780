import { LineItem } from "../types/line-items.type"
import { ShippingCartProduct } from "../types/shipping-cart-product.type"
import { Adapter } from "./interface/adapter.type"

const convertForwardToBackwardEntity = (colorRu: string): boolean => {
    switch(colorRu) {
        case "instock":
            return true;
        case "outofstock":
            return false
        default:
            return false;
    }
}

export const stockStatusToBooleanAdapter:Adapter<string, boolean> = {
    convertForwardToBackwardEntity
}