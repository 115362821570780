import { createAsyncThunk } from "@reduxjs/toolkit";
import { dal } from "../../DAL/DAL";
import { BannerDTO } from "../../models/types/banner-dto.type";

export const fetchBanners = createAsyncThunk<BannerDTO[]>(
    'banners/getBannersContent',
    async() => {
        const response:BannerDTO[] = await dal.fetchBanners();
        return response;
    }
)