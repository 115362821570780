import { LineItem } from "../types/line-items.type"
import { ShippingCartProduct } from "../types/shipping-cart-product.type"
import { Adapter } from "./interface/adapter.type"

const convertForwardToBackwardEntity = (colorRu: string): string => {
    switch(colorRu) {
        case "Бежевый":
            return "beige";
        case "Белый":
            return "white";
        case "Жёлтый":
            return "yellow";
        case "Зелёный":
            return "green";
        case "Коричневый":
            return "brown";
        case "Красный":
            return "red";
        case "Оранжевый":
            return "orange";
        case "Серый":
            return "gray";
        case "Синий":
            return "blue";
        case "Фиолетовый":
            return "purple";
        case "Чёрный":
            return "black";
        default:
            return "white";
    }
}

export const colorToCSSAdapter:Adapter<string, string> = {
    convertForwardToBackwardEntity
}