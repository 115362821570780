import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RembayLogo from "../../local/png-webp/header/logo.png";
import { ReactComponent as ShippingCartDesctop } from "../../local/svg/header/cart-desctop.svg";
import { ReactComponent as SearchDesctop } from "../../local/svg/header/search-desctop.svg";
import { ReactComponent as ArrowDesctop } from "../../local/svg/header/arrow-desctop.svg";
import { ReactComponent as PhoneDesctop } from "../../local/svg/header/phone-desctop.svg";
import { ReactComponent as FavDesctop } from "../../local/svg/header/fav-desctop.svg";
import { ReactComponent as ProfileDesctop } from "../../local/svg/header/profile-desctop.svg";
import { ReactComponent as ShippingCartMobile } from "../../local/svg/header/cart-mobile.svg";
import { ReactComponent as FavMobile } from "../../local/svg/header/fav-mobile.svg";
import { ReactComponent as ProfileMobile } from "../../local/svg/header/profile-mobile.svg";

import { ReactComponent as OpenMenuSvg } from "../../local/svg/header/menu-open.svg";
import { ReactComponent as CloseMenuSvg } from "../../local/svg/header/menu-close.svg";


import { useSelector } from "react-redux";
import { shippingCartSelectors } from "../../store/shipping-cart/shipping-cart.selector";
import { categorySelectors } from "../../store/categories/categories.selector";
import { useAppDispatch } from "../../store/store.hooks";
import { CategoryDTO } from "../../models/types/category-dto.type";
import { ProductsActions } from "../../store/products/products.slice";
import { isMobile } from "react-device-detect";
import { productsSelectors } from "../../store/products/products.selector";
import { fetchCategories } from "../../store/categories/categories.thunk";

import ContentLoader from "react-content-loader";
import './Header.scss';
import { LoadingStatus } from "../../models/enums/loading-status.enum";
import { ShippingCartProduct } from "../../models/types/shipping-cart-product.type";
import { ShippingCartActions } from "../../store/shipping-cart/shipping-cart.slice";

export const Header:FC = () => {
    const dispatch = useAppDispatch();
    
    const navigate = useNavigate();

    const currentCategory = useSelector(productsSelectors.selectCurrentCategory);
    const shippingCartFullQuantity = useSelector(shippingCartSelectors.selectShippingCartFullQuantity);
    const productCategoriesList = useSelector(categorySelectors.selectProductParentCategories);
    const categoryLoadigStatus = useSelector(categorySelectors.selectLoadingStatus);
    const searchQuery = useSelector(productsSelectors.selectSearchQuery);
    const shippingCartState = useSelector(shippingCartSelectors.selectShippingCart);

    const [isMenuOpen, setMenuOpenStatus] = useState<boolean>(false);

    const [dropDownOpenStatus, setDropDownOpenStatus] = useState<boolean>(false);
  
    const [clickedLinkStatus, setClickedLinkStatus] = useState<boolean>(false)
    useEffect(() => {
        if(clickedLinkStatus) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setClickedLinkStatus(false);
        }
    },[clickedLinkStatus]);

    useEffect(() => {
        
      dispatch(fetchCategories());
      const shippingCartItems: ShippingCartProduct[] = JSON.parse(localStorage.getItem('shippingCartItems') ?? '[]');
      if (shippingCartItems.length) {
       dispatch(ShippingCartActions.setShippingCart(shippingCartItems));
      }
    }, []);

    useEffect(() => {
        localStorage.setItem('shippingCartItems', JSON.stringify(shippingCartState));
    },[shippingCartFullQuantity])

    const setSearchQuery = (value:string) => {
        dispatch(ProductsActions.setSearchQuery(value));
        //dispatch(ProductsActions.setReloadStatus(true));
    }

    const setFilterCategory = (category: CategoryDTO) => {
        dispatch(ProductsActions.clearProductsList());
        dispatch(ProductsActions.setProductParentCategory(category));
        //dispatch(ProductsActions.addCategory(category));
        dispatch(ProductsActions.setReloadStatus(true));
    };

    const setDefaultCategoryWhileReturningToMainPage = () => {
        dispatch(ProductsActions.setProductDefaultCategory());
        dispatch(ProductsActions.clearProductsList());
    }

    const toCatalog = () => {
        dispatch(ProductsActions.setDefaultFilter());
        dispatch(ProductsActions.clearProductsList());
        dispatch(ProductsActions.setPage(1));
        dispatch(ProductsActions.setReloadStatus(true));
        setMenuOpenStatus(false);
    }

    const onEnterPressSearch = (key: string) => {
        if (key === "Enter") {
            dispatch(ProductsActions.clearProductsList());
            dispatch(ProductsActions.setPage(1));
            dispatch(ProductsActions.setReloadStatus(true));
            setMenuOpenStatus(false);
            navigate("/products");
        }
    }


    return(
        <header className={`header header-is-menu-open-${isMenuOpen} header__is-mobile-${isMobile}`}>
            <div className={`header__container header__container-is-menu-open-${isMenuOpen}`}>
                <div className="header-logo__container">
                    <Link to="/" onClick={() => setDefaultCategoryWhileReturningToMainPage()}>
                        <img src={RembayLogo} className="header-logo__img"/>
                    </Link>
                    <h1 className="header-logo__head">
                        Интернет - магазин
                    </h1>
                </div>
                <div className="header-search__container">
                    <input onKeyDown={(event) =>  onEnterPressSearch(event.key) } value={searchQuery} onChange={event => setSearchQuery(event.target.value)} className="header-search__element" type="text" placeholder="Поиск товара"/>
                    <Link onClick={() => toCatalog()} className="header-search__svg-container" to='/products'>
                        <SearchDesctop  className="header-search__svg" />
                    </Link>
                </div>


                
                <div className="header-button__container">
                    <div className="header-button__element cart">
                        <Link to='/shipping-cart' >
                            <ShippingCartDesctop className="header-button__svg header-button__svg-desctop" />
                            <ShippingCartMobile className="header-button__svg header-button__svg-mobile" />
                        </Link>
                        {
                            shippingCartFullQuantity > 0 ?
                            <p className="header-button__svg-quantity">
                                {shippingCartFullQuantity}
                            </p>:null                            
                        }

                    </div>
                    <div className="header-button__element profile">
                        <Link to='/profile' >
                            <ProfileDesctop className="header-button__svg header-button__svg-desctop" />
                            <ProfileMobile className="header-button__svg header-button__svg-mobile" />
                        </Link>
                    </div>
                    <div className="header-button__element fav">
                        <Link to='/favorites' >
                            <FavDesctop className="header-button__svg header-button__svg-desctop" />
                            <FavMobile className="header-button__svg header-button__svg-mobile" />
                        </Link>
                    </div>
                </div>
                <div className="header-menu-btn__container">
                    <div onClick={() => setMenuOpenStatus(true)} className="header-menu-btn__element open-menu">
                        <OpenMenuSvg className="header-menu-btn__svg" />
                    </div>
                    <div onClick={() => setMenuOpenStatus(false)} className="header-menu-btn__element close-menu">
                        <CloseMenuSvg className="header-menu-btn__svg" />
                    </div>
                </div>
                <div className="header-nav__container">
                    <Link onClick={() => setMenuOpenStatus(false)} to='/' className="header-nav__element">
                        Главная
                    </Link>
                    <Link onClick={() => toCatalog()} to='/products' className="header-nav__element">
                        Каталог
                    </Link>
                    <Link onClick={() => {setClickedLinkStatus(true); setMenuOpenStatus(false)}} to='/info' className="header-nav__element">
                        Информация
                    </Link>
                    <Link onClick={() => {setClickedLinkStatus(true); setMenuOpenStatus(false)}} to='/sales' className="header-nav__element">
                        Акции
                    </Link>
                </div>
            </div>
            <ul className={`header-categories__container header-categories__container-loading-status-${categoryLoadigStatus}`}>
                <div className="header-delivery__container">
                    Доставка по РБ - Бесплатно
                </div>
                <div className="header-delivery__container">
                    <Link to="/partners" className="header-delivery__link">
                        Оптовым клиентам
                    </Link>
                </div>
                <div className="header-adress__container">
                    <ArrowDesctop onClick={() => setDropDownOpenStatus(!dropDownOpenStatus)} className={`header-adress__svg header-adress__svg-opened-${dropDownOpenStatus}`} />
                    <div className={`header-adress__drop-down-container header-adress__drop-down-container-opened-${dropDownOpenStatus}`}>
                        <p className="header-adress__drop-down-element">
                            ул.Крыленко,9 <br />
                            8022 73-63-42 
                        </p>
                        <p className="header-adress__drop-down-element">
                            ул. Островского,5 <br />
                            ТЦ “ Планета Green” <br />
                            8022 42-60-01
                        </p>
                        <p className="header-adress__drop-down-element">
                            ул.Белинского ,28 <br />
                            ТЦ  “Гринвич”
                        </p>
                    </div>
                </div>
                <div className="header-phone__container">
                    <PhoneDesctop className="header-phone__svg" />
                    <a href="tel:+375447665050" className="header-phone__element header-delivery__link">
                        +375 44 766-50-50 
                    </a>
                </div>
                {/*productCategoriesList.map(category => 
                    <Link key={category.id} to="/products" className={`header-categories__element header-categories__element-selected-${category.id === currentCategory.id ? true : false}`} onClick={() => setFilterCategory(category)}>
                        {category.name}
                    </Link>    
                )*/}
                {/*categoryLoadigStatus !== LoadingStatus.Fullfiled ? 
                    <ContentLoader
                    speed={1}
                    width={800}
                    height={15}
                    backgroundColor="#F2EEE1"
                    foregroundColor="#dcd7c7"
                    >
                        <rect x="90" y="0" rx="8" ry="8" width="305" height="15" />
                        <rect x="405" y="0" rx="8" ry="8" width="305" height="15" />
                    </ContentLoader> : null*/

                }
            </ul>
        </header>
    )
}