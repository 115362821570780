import { RootState } from "../store";
import { ProductsAdapter } from "./products.slice";
import { createSelector } from "@reduxjs/toolkit";
import { bl } from "../../BL/BL";

const productsState = (state:RootState) => state.products;

const productsEntitySelectors = ProductsAdapter.getSelectors(productsState);

const selectAllProducts = createSelector(productsEntitySelectors.selectAll, products => products);

const selectCurrentCategory = createSelector(productsState, state => state.filter.selectedParentCategory);

const selectCurrentPage = createSelector(productsState, state => state.currentPage);

const selectFilteredProducts = createSelector(productsState, productsEntitySelectors.selectAll, (state, products) => {
    //const {ingredients} = state.filter;
    return products;
    //return products.filter(product => ingredients.length === 0 ? true : bl.isFilterIngredientsIncludesInProduct(ingredients, product.product));
});

const selectProductsQuantity = createSelector(productsEntitySelectors.selectAll, products => products.length);

const selectSearchQuery = createSelector(productsState, state => state.filter.searchQuery);

const selectTopSellerProducts = createSelector(productsState, productsEntitySelectors.selectAll, (state, products) => {
    return products.filter(product => product.isTopSeller);
});

const selectOnSalesProducts = createSelector(productsState, productsEntitySelectors.selectAll, (state, products) => {
    return products.filter(product => product.isOnSale);
});

const selectNewProducts = createSelector(productsState, productsEntitySelectors.selectAll, (state, products) => {
    return products.filter(product => product.isNewProduct);
});

const selectLoadingStatus = createSelector(productsState, state => {
    return state.loadingStatus;
})

const selectReloadStatus = createSelector(productsState, state => {
    return state.reload;
})

const selectCurrentCategories = createSelector(productsState, state => {
    return state.filter.selectedCategories;
})

const selectFilter = createSelector(productsState, state => {
    return state.filter;
})

const selectLeatherFilter = createSelector(productsState, state => {
    return state.filter.selectedLeather;
})

const selectSexFilter = createSelector(productsState, state => {
    return state.filter.selectedSex;
})

const selectColorsFilter = createSelector(productsState, state => {
    return state.filter.selectedColor;
})
const selectProductionFilter = createSelector(productsState, state => {
    return state.filter.selectedProduction;
})

const selectMoneyFilter = createSelector(productsState, state => {
    return state.filter.moneyLewelCost;
})

const selectLengthFilter = createSelector(productsState, state => {
    return state.filter.lengthLewelCost;
})

const selectWidthFilter = createSelector(productsState, state => {
    return state.filter.widthLewelCost;
})

const selectHeightFilter = createSelector(productsState, state => {
    return state.filter.heightLewelCost;
})

const selectEmptyStatus = createSelector(productsState, state => {
    return state.empty;
})

export const productsSelectors = {
    selectSearchQuery,
    selectEmptyStatus,
    selectCurrentCategories,
    selectReloadStatus,
    selectHeightFilter,
    selectWidthFilter,
    selectLengthFilter,
    selectMoneyFilter,
    selectProductionFilter,
    selectSexFilter,
    selectLeatherFilter,
    selectProductsQuantity,
    selectAllProducts,
    selectFilteredProducts,
    selectTopSellerProducts,
    selectOnSalesProducts,
    selectNewProducts,
    selectCurrentCategory,
    selectCurrentPage,
    selectLoadingStatus,
    selectFilter,
    selectColorsFilter,

}