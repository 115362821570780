import { FC, useState } from "react";
import { Link } from "react-router-dom";
import './Partners.scss';

export const Partners:FC = () => {
    const [name, setName] = useState<string>('');
    const [job, setJob] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [companyAdress, setCompanyAdress] = useState<string>('');
    const [unp, setUnp] = useState<string>('');

    const sendOrder = () => {

    }

    return(
        <section className="partners__container">
            <p className="catalog__category-heading">
                <Link to="/" className="info__main-link">Главная</Link> - Оптовым клиентам
            </p>
            <h2 className="partners__heading">
                Стать оптовым клиентом
            </h2>
            <p className="partners__description">
                Отправьте свою заявку и мы свяжемся с вами
            </p>
            <div className="partners__form-container">
                <div className="partners__form-element">
                    <p className="partners__form-title">
                        ФИО
                    </p>
                    <input className="partners__form-select-text"  value={name} onChange={event => setName(event.target.value)}  type="text" />
                </div>
                <div className="partners__form-element">
                    <p className="partners__form-title">
                        Должность
                    </p>
                    <input className="partners__form-select-text"  value={job} onChange={event => setJob(event.target.value)}  type="text" />
                </div>
                <div className="partners__form-element">
                    <p className="partners__form-title">
                        E-mail
                    </p>
                    <input className="partners__form-select-text"  value={email} onChange={event => setEmail(event.target.value)}  type="email" />
                </div>
                <div className="partners__form-element">
                    <p className="partners__form-title">
                        Контактный телефон
                    </p>
                    <input className="partners__form-select-text"  value={phone} onChange={event => setPhone(event.target.value)}  type="tel" />
                </div>
                <div className="partners__form-element">
                    <p className="partners__form-title">
                        Название компании
                    </p>
                    <input className="partners__form-select-text"  value={companyName} onChange={event => setCompanyName(event.target.value)}  type="text" />
                </div>
                <div className="partners__form-element">
                    <p className="partners__form-title">
                        Юридический адресс
                    </p>
                    <input className="partners__form-select-text"  value={companyAdress} onChange={event => setCompanyAdress(event.target.value)}  type="text" />
                </div>
                <div className="partners__form-element">
                    <p className="partners__form-title">
                        УНП (ИНН)
                    </p>
                    <input className="partners__form-select-text"  value={unp} onChange={event => setUnp(event.target.value)}  type="text" />
                </div>
            </div>

            <button onClick={() => sendOrder()} className="shipping-cart__purchase-btn">
                Отправить заявку
            </button>
        </section>
    );
}