import { configureStore } from "@reduxjs/toolkit";
import ProductReducer from "./products/products.slice";
import ShippingCartReducer from "./shipping-cart/shipping-cart.slice";
import TagsReducer from "./tags/tags.slice";
import CategoryReducer from "./categories/categories.slice";
import OrderInfo from "./order-info/order-info.slice";
import BannersReducer from "./banners/banners.slice";

export const store = configureStore({
    reducer: {
        products: ProductReducer,
        tags: TagsReducer,
        categories: CategoryReducer,
        shippingCart: ShippingCartReducer,
        orderInfo: OrderInfo,
        banners: BannersReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch