import { FC, useState } from "react";
import { shippingCartSelectors } from "../../store/shipping-cart/shipping-cart.selector";
import { useSelector } from "react-redux";
import { ShippingCartProductCard } from "./ShippingCartProductCard/ShippingCartProductCard";
import { ReactComponent as EmptyShippingCartSVG} from "../../local/svg/shipping-cart/empty-cart.svg";
import { ReactComponent as ShippingCartSVG} from "../../local/svg/shipping-cart/cart.svg";
import { ReactComponent as DeliverySVG} from "../../local/svg/shipping-cart/delivery.svg";
import { ReactComponent as PersonSVG} from "../../local/svg/shipping-cart/person-info.svg";
import { ReactComponent as PaymentSVG} from "../../local/svg/shipping-cart/payment.svg";
import { ReactComponent as ClockSVG} from "../../local/svg/shipping-cart/clock.svg";
import { ReactComponent as UnavailableSvg } from "../../local/svg/product/unavailable.svg";
import { Link } from "react-router-dom";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './ShippingCart.scss';
import { PaymentMethodEnum } from "../../models/enums/payment-method.enum";
import { productToLineItemsListAdapter } from "../../models/adapters/product-to-line-items.adapter";
import { bl } from "../../BL/BL";
import { ProductsActions } from "../../store/products/products.slice";
import { useAppDispatch } from "../../store/store.hooks";

export const ShippingCart:FC = () => {
    const dispatch = useAppDispatch();
    const shippingCart = useSelector(shippingCartSelectors.selectShippingCart);
    const shippingCartCost = useSelector(shippingCartSelectors.selectShippingCartFullQPrice);
    const selfDeliveryOptions = [
        'ул.Крыленко,9', 
        'ул. Островского,5 ТЦ “ Планета Green”', 
        'ул.Белинского ,28 ТЦ “Гринвич”'
      ];
    const [selfDeliveryOption, setSelfDeliveryOption] = useState<string>(selfDeliveryOptions[0])
    const [selfDeliveryMethodChoosedStatus, setSelfdeliveryMethodChoosedStatus] = useState<boolean>(true);
    const [mailDelivery, setMailDelivery] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEnum>(PaymentMethodEnum.PayOnMail);
    const [messageSended, setMessageSendedStatus] = useState<boolean>(false);


    const sendOrder = () => {
        bl.sendOrder(bl.setOrder(bl.setBillingAdress(name,email,phoneNumber,(selfDeliveryMethodChoosedStatus ? selfDeliveryOption : mailDelivery)), paymentMethod, false, productToLineItemsListAdapter.convertForwardToBackwardEntity(shippingCart)))
        .then(
            response => {
                bl.sendTelegrammOrderMessage(
                    bl.buildTelegrammOrderMessage(
                        name,
                        email,
                        phoneNumber,
                        response.id,
                        shippingCart, 
                        shippingCartCost, 
                        comment,
                        paymentMethod,
                        selfDeliveryMethodChoosedStatus ? selfDeliveryOption : mailDelivery
                    )
                );
                setMessageSendedStatus(true);
            }
        );
    }


    const setPaymentMethodStatus = (method: PaymentMethodEnum): boolean => {
        if(selfDeliveryMethodChoosedStatus) {
            switch (method) {
                case PaymentMethodEnum.PayOnMail:
                    return true;
                case PaymentMethodEnum.SelfDelivery:
                    return false;
            }
        } else {
            switch (method) {
                case PaymentMethodEnum.PayOnMail:
                    return false;
                case PaymentMethodEnum.SelfDelivery:
                    return true;
            }
        }
            
    }

    const returnToCatalog = () => {
        dispatch(ProductsActions.clearProductsList());
        //dispatch(ProductsActions.setProductDefaultCategory());
        dispatch(ProductsActions.setDefaultFilter());
        dispatch(ProductsActions.setReloadStatus(true));

    }

    return(
        <section className="shipping-cart__section-container">
            {   shippingCart.length > 0 ?
                <div className="shipping-cart__data-container">
                    <div className="shipping-cart__data-element">
                        <div className="shipping-cart__heading-container">
                            <div className="shipping-cart__heading-svg-container">
                                <ShippingCartSVG className="shipping-cart__heading-svg" />
                            </div>
                            <h2 className="shipping-cart__heading-title">
                                Товары в заказе
                            </h2>
                        </div>
                        <div className="shipping-cart__list">
                            {
                                shippingCart.map(product => <ShippingCartProductCard key={product.id} {...product} />)
                            }
                        </div>
                    </div>
                    <div className="shipping-cart__data-element">
                        <div className="shipping-cart__heading-container">
                            <div className="shipping-cart__heading-svg-container">
                                <PersonSVG className="shipping-cart__heading-svg" />
                            </div>
                            <h2 className="shipping-cart__heading-title">
                                Информация о покупателе
                            </h2>
                        </div>
                        <form className="shipping-cart__form-container">
                            <div className="shipping-cart__form-input-container">
                                <input className="shipping-cart__form-input-element" placeholder="ФИО" value={name} onChange={event => setName(event.target.value)} type="text" />
                                <input className="shipping-cart__form-input-element" placeholder="+375 __ ___-__-__" value={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} type="tel" />
                                <input className="shipping-cart__form-input-element" placeholder="email@domen.com" value={email} onChange={event => setEmail(event.target.value)} type="email" />
                            </div>
                            <textarea placeholder="Комментарий..." className="shipping-cart__form-input-area" value={comment} onChange={event => setComment(event.target.value)}/>
                        </form>
                    </div>
                    <div className="shipping-cart__data-element">
                        <div className="shipping-cart__heading-container">
                            <div className="shipping-cart__heading-svg-container">
                                <DeliverySVG className="shipping-cart__heading-svg" />
                            </div>
                            <h2 className="shipping-cart__heading-title">
                                Способ доставки
                            </h2>
                        </div>
                        <form className="shipping-cart__form-container">
                            <div className="shipping-cart__form-select-container">
                                <div className="shipping-cart__radio-container">
                                    <input className="shipping-cart__radio-input" onClick={() => {if(!selfDeliveryMethodChoosedStatus) {setSelfdeliveryMethodChoosedStatus(!selfDeliveryMethodChoosedStatus); setPaymentMethod(PaymentMethodEnum.PayOnMail)}}} checked={selfDeliveryMethodChoosedStatus} type="radio" />
                                    <p className="shipping-cart__radio-title">
                                        Самовывоз по указанному адресу магазина
                                    </p>
                                </div>
                                <Dropdown className="shipping-cart__form-select-dropdown" disabled={!selfDeliveryMethodChoosedStatus} options={selfDeliveryOptions} value={selfDeliveryOption} onChange={value => setSelfDeliveryOption(value.value)} placeholder="Выберите адрес"/>
                            </div>
                            <div className="shipping-cart__form-select-container">
                                <div className="shipping-cart__radio-container">
                                    <input className="shipping-cart__radio-input" onClick={() => {if(selfDeliveryMethodChoosedStatus) {setSelfdeliveryMethodChoosedStatus(!selfDeliveryMethodChoosedStatus); setPaymentMethod(PaymentMethodEnum.SelfDelivery)}}} checked={!selfDeliveryMethodChoosedStatus} type="radio" />
                                    <p className="shipping-cart__radio-title">
                                        Белпочта
                                    </p>
                                </div>
                                <input className="shipping-cart__form-select-text" placeholder="Адрес почтового отделения и домашний адрес" value={mailDelivery} onChange={event => setMailDelivery(event.target.value)} disabled={selfDeliveryMethodChoosedStatus} type="text" />
                            </div>
                        </form>
                    </div>
                    <div className="shipping-cart__data-element">
                        <div className="shipping-cart__heading-container">
                            <div className="shipping-cart__heading-svg-container">
                                <PaymentSVG className="shipping-cart__heading-svg" />
                            </div>
                            <h2 className="shipping-cart__heading-title">
                                Способ оплаты
                            </h2>
                        </div>
                        <div className="shipping-cart__horizontal-container">
                            <button onClick={() => setPaymentMethodStatus(PaymentMethodEnum.PayOnMail)} className={`shipping-cart__form-select-btn shipping-cart__form-select-btn-selected-${paymentMethod == PaymentMethodEnum.SelfDelivery ? true : false}`} disabled={!setPaymentMethodStatus(PaymentMethodEnum.SelfDelivery)}>
                                Наложенный платёж
                            </button>
                            <button onClick={() => setPaymentMethodStatus(PaymentMethodEnum.SelfDelivery)} className={`shipping-cart__form-select-btn shipping-cart__form-select-btn-selected-${paymentMethod == PaymentMethodEnum.PayOnMail ? true : false}`} disabled={!setPaymentMethodStatus(PaymentMethodEnum.PayOnMail)}>
                                Самовывоз
                            </button>
                        </div>
                    </div>
                    <div className="shipping-cart__data-element">
                        <h3 className="shipping-cart__heading-title">
                            Итого
                        </h3>
                        <div className="shipping-cart__purchase-cost-container">
                            <p className="shipping-cart__purchase-cost-title">
                                Общая стоимость
                            </p>
                            <p className="shipping-cart__purchase-cost-quantity">
                                {shippingCartCost} BYN
                            </p>
                        </div>
                        <button onClick={() => sendOrder()} className="shipping-cart__purchase-btn">
                            Оформить заказ
                        </button>
                    </div>

                </div>:
                <div className="shipping-cart__data-container">
                    {/*<h2 className="shipping-cart__heading">
                        Ваша корзина
                    </h2>*/}
                    <div className="shipping-cart__empty-container">
                        <EmptyShippingCartSVG className="shipping-cart__empty-svg" />
                        <p className="shipping-cart__empty-info">
                            на данный момент у вас нет <br/>товаров в корзине
                        </p>
                        <Link onClick={() => returnToCatalog()} to='/products' className="shipping-cart__empty-btn">Вернуться к покупкам</Link>
                    </div>
                </div>                
            }

            <div className={`product__message-sended-bkg product__message-sended-bkg-status-${messageSended}`}>
                <div className="product__message-sended-container">
                    <button className="product__message-sended-close" onClick={() => setMessageSendedStatus(false)}>
                        <UnavailableSvg className="product__message-sended-svg" />
                    </button>      
                    <ClockSVG className="shipping-cart__clock" />                          
                    <p className="product__message-sended-text">
                        Спасибо, ваш заказ принят.
                    </p>
                    <p className="shipping-cart__empty-info">
                        В ближайшее время с Вами свяжется наш специалист.
                    </p>
                </div>
            </div>
            
        </section>
    );
}