import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../models/enums/loading-status.enum";
import { ShippingCartProduct } from "../../models/types/shipping-cart-product.type";

export const ShippingCartAdapter = createEntityAdapter<ShippingCartProduct>();

export const ShippingCartSlice = createSlice({
    name: 'ShippingCart',
    initialState: ShippingCartAdapter.getInitialState({
        loading: LoadingStatus.Idle,
    }),
    reducers: {
        addProductToCart(state, action: PayloadAction<ShippingCartProduct>) {
            ShippingCartAdapter.addOne(state, action.payload);
        },
        removeProductfromCart(state, action: PayloadAction<ShippingCartProduct>) {
            ShippingCartAdapter.removeOne(state, action.payload.id);
        },  
        incrementProductfromCart(state, action: PayloadAction<ShippingCartProduct>) {
            action.payload.quantity !== 0 ? 
                ShippingCartAdapter.updateOne(state, {id: action.payload.id, changes: action.payload} ):
                ShippingCartAdapter.removeOne(state, action.payload.id);

        }, 
        setShippingCart(state, action: PayloadAction<ShippingCartProduct[]>) {
            ShippingCartAdapter.setAll(state, action.payload);
        }
    }
});

export const ShippingCartActions = ShippingCartSlice.actions;
export default ShippingCartSlice.reducer;