import { FC } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store/store.hooks";
import { ProductsActions } from "../../store/products/products.slice";
import './NotFound.scss'

export const NotFound: FC = () => {
    const dispatch = useAppDispatch();

    const returnToCatalog = () => {
        dispatch(ProductsActions.clearProductsList());
        //dispatch(ProductsActions.setProductDefaultCategory());
        dispatch(ProductsActions.setDefaultFilter());
        dispatch(ProductsActions.setReloadStatus(true));

    }

    return(
        <section className="not-found__container">
                <div className="shipping-cart__data-container">

                    <div className="shipping-cart__empty-container">
                        <p className="shipping-cart__empty-info">
                            Увы, но данной страницы(скорее всего пока) не существует <br/>возможно вы желаете вернуться в каталог
                        </p>
                        <Link onClick={() => returnToCatalog()} to='/products' className="shipping-cart__empty-btn">Перейти в каталог</Link>
                    </div>
                </div>   
        </section>
    )
}