import { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { List } from "./List/List";
import { productsSelectors } from "../../store/products/products.selector";
import { useAppDispatch } from "../../store/store.hooks";
import { fetchProductsPerPageByArgumentsAndCategory } from "../../store/products/products.thunk";
import { ProductsActions } from "../../store/products/products.slice";
import { LoadingStatus } from "../../models/enums/loading-status.enum";
import { ReactComponent as FilterSVG} from "../../local/svg/products/filter.svg";
import { ReactComponent as CloseFilerSVG} from "../../local/svg/header/menu-close.svg";
import './Products.scss';
import { Filter } from "./Filter/Filter";
import { bl } from "../../BL/BL";

export const Products:FC = () => {
    const dispatch = useAppDispatch();

    const currentCategory = useSelector(productsSelectors.selectCurrentCategory);
    const currentPage = useSelector(productsSelectors.selectCurrentPage);
    const productsQuantity = useSelector(productsSelectors.selectProductsQuantity);
    const productFetchingStatus = useSelector(productsSelectors.selectLoadingStatus);
    const reloadStatus = useSelector(productsSelectors.selectReloadStatus);
    const emptyStatus = useSelector(productsSelectors.selectEmptyStatus);
    const searchValue = useSelector(productsSelectors.selectSearchQuery);

    const categories = useSelector(productsSelectors.selectCurrentCategories);
    const moneyRange = useSelector(productsSelectors.selectMoneyFilter);
    const leatherChecked = useSelector(productsSelectors.selectLeatherFilter);
    const colorChecked = useSelector(productsSelectors.selectColorsFilter);
    const sexChecked = useSelector(productsSelectors.selectSexFilter);
    const productionChecked = useSelector(productsSelectors.selectProductionFilter);
    const [isFilterOpen, setIsFilterOpenStatus] = useState<boolean>(false);

    const setNextPage = () => {
        dispatch(ProductsActions.setPage(currentPage+1));
        dispatch(ProductsActions.setReloadStatus(true));
    }

    const buildArgsUrl = ():string => {
        console.log(String(bl.setAttributeUrlrequest(leatherChecked,'pa_material') + bl.setAttributeUrlrequest(colorChecked,'pa_color') + bl.setAttributeUrlrequest(sexChecked,'pa_sex') + bl.setAttributeUrlrequest(productionChecked,'pa_origin')))

        return String(bl.setAttributeUrlrequest(leatherChecked,'pa_material') + bl.setAttributeUrlrequest(colorChecked,'pa_color') + bl.setAttributeUrlrequest(sexChecked,'pa_sex') + bl.setAttributeUrlrequest(productionChecked,'pa_origin'));
    }
    
    const returnToCatalog = () => {
        dispatch(ProductsActions.clearProductsList());
        //dispatch(ProductsActions.setProductDefaultCategory());
        dispatch(ProductsActions.setDefaultFilter());
        dispatch(ProductsActions.setReloadStatus(true));

    }

    useEffect(() => {
        if(reloadStatus) {
            window.scrollTo({ top: -1000, behavior: "smooth" });
            dispatch(
                fetchProductsPerPageByArgumentsAndCategory({
                    args: bl.buildRequestFilterArguments(bl.setCategoryUrlRequest(categories), buildArgsUrl(), bl.setBudgetUrlrequest(moneyRange), bl.setSearchUrlrequest(searchValue)),
                    page: currentPage
                })
            ) 
        }
      }, [reloadStatus]);

    return(
        <section id="catalog" className="catalog__container">
            <h2 className="catalog__category-heading">
                <p className="catalog__category-link" onClick={() => returnToCatalog()}>Каталог</p>
                {currentCategory.id !== 0 ? <p className="catalog__category-element"> - {currentCategory.name}</p>:null}
            </h2>
            <button className={`catalog__open-filter-btn catalog__open-filter-btn-opened-${isFilterOpen}`} onClick={() => setIsFilterOpenStatus(!isFilterOpen)}>Сортировка и фильтры {isFilterOpen? <CloseFilerSVG className="catalog__open-filter-svg-close" /> : <FilterSVG className="catalog__open-filter-svg-open" />}</button>
            <div className="catalog__ui-container">
                <div className="catalog__list-container">
                    <div className={`catalog__filter-container catalog__filter-container-opened-${isFilterOpen}`}>
                        <Filter />
                    </div>
                    <List />
                </div>


                {(productsQuantity % 24 === 0) && (productFetchingStatus == LoadingStatus.Fullfiled) && !emptyStatus ? 
                            <button onClick={() => setNextPage()} className="catalog__btn">
                                Загрузить больше товаров
                            </button>: null
                        }
            </div>
            
        </section>
    )
}