import { FC } from "react";
import './Filter.scss'
import { Slider } from "@mui/material";
import { colorsCollection } from "../../../models/consts/colors.collection";
import { colorToCSSAdapter } from "../../../models/adapters/color-to-css.adapter";
import { sexCollection } from "../../../models/consts/sex.collection";
import { productionCollection } from "../../../models/consts/production.collection";
import { leatherCollection } from "../../../models/consts/leather.collection";
import { useSelector } from "react-redux";
import { productsSelectors } from "../../../store/products/products.selector";
import { useAppDispatch } from "../../../store/store.hooks";
import { ProductsActions } from "../../../store/products/products.slice";
import { AttributeFilterTerm } from "../../../models/types/filter-attribute-term.type";
import Checkbox from '@mui/material/Checkbox';
import { brown } from '@mui/material/colors';
import { CategoryDTO } from "../../../models/types/category-dto.type";
import { categorySelectors } from "../../../store/categories/categories.selector";


export const Filter:FC = () => {
    const dispatch = useAppDispatch();

    const moneyRange = useSelector(productsSelectors.selectMoneyFilter);
    const leatherChecked = useSelector(productsSelectors.selectLeatherFilter);
    const colorChecked = useSelector(productsSelectors.selectColorsFilter);
    const sexChecked = useSelector(productsSelectors.selectSexFilter);
    const productionChecked = useSelector(productsSelectors.selectProductionFilter);
    const selectedCategories = useSelector(productsSelectors.selectCurrentCategories);
    const allCategories = useSelector(categorySelectors.selectProductChildrenCategories);


    const setFilter = () => {
        dispatch(ProductsActions.clearProductsList())
        dispatch(ProductsActions.setPage(1));
        dispatch(ProductsActions.setReloadStatus(true));
    }

    const isCategoryInFilter = (selectedCategory: CategoryDTO): boolean => {
        return selectedCategories.find(category => category.id === selectedCategory.id) ? true : false;
    }

    const addCategoryToFilter = (category: CategoryDTO) => {
        dispatch(isCategoryInFilter(category) ? ProductsActions.removeCategory(category.id) : ProductsActions.addCategory(category));
    }

    const isAttributeInFilter = (selectedAttribute: AttributeFilterTerm, filterCollection: AttributeFilterTerm[]): boolean => {
        return filterCollection.find(attribute => attribute.id === selectedAttribute.id) ? true : false;
    }

    const setLeatherCheckedStatus = (attribute: AttributeFilterTerm) => {
        isAttributeInFilter(attribute, leatherChecked) ? dispatch(ProductsActions.removeLeather(attribute.id)) : dispatch(ProductsActions.addLeather(attribute));
    }

    const setColorCheckedStatus = (attribute: AttributeFilterTerm) => {
        isAttributeInFilter(attribute, colorChecked) ? dispatch(ProductsActions.removeColor(attribute.id)) : dispatch(ProductsActions.addColor(attribute));
    }

    const setSexCheckedStatus = (attribute: AttributeFilterTerm) => {
        isAttributeInFilter(attribute, sexChecked) ? dispatch(ProductsActions.removeSex(attribute.id)) : dispatch(ProductsActions.addSex(attribute));
    }

    const setProductionCheckedStatus = (attribute: AttributeFilterTerm) => {
        isAttributeInFilter(attribute, productionChecked) ? dispatch(ProductsActions.removeProduction(attribute.id)) : dispatch(ProductsActions.addProduction(attribute));
    }

    const handleMoneyRangeChange = (event: Event, newValue: number | number[]) => {
        dispatch(ProductsActions.addMoneyFilter(newValue as [number,number]))
        //setMoneyRange(newValue as number[]);
      };

    return(
        <div className="filter__page-container">

            {/*<div className="filter__container">
                <p className="filter__heading">
                    Типаж
                </p>
                {
                    sexCollection.map(sex =>
                        <div key={sex.id} className={`filter__element-container`}>
                            <Checkbox
                            sx={{
                                color: brown[800],
                                '&.Mui-checked': {
                                  color: brown[800],
                                },
                              }}
                            onChange={() => setSexCheckedStatus(sex)} checked={sexChecked.includes(sex)} className={`filter__element-checkbox filter__element-checkbox-selected-${sexChecked.includes(sex)}`} />
                            <p className={`filter__element-title`}>
                                {sex.name}

                            </p>
                        </div>
                    )
                }
            </div>*/}
            <div className="filter__container">
                <p className="filter__heading">
                    Тип
                </p>
                {
                    allCategories.map(category =>
                        <div key={category.id} className={`filter__element-container`}>
                            <Checkbox
                            sx={{
                                color: brown[800],
                                '&.Mui-checked': {
                                  color: brown[800],
                                },
                              }}
                            onChange={() => addCategoryToFilter(category)} checked={selectedCategories.includes(category)} className={`filter__element-checkbox filter__element-checkbox-selected-${selectedCategories.includes(category)}`} />
                            <p className={`filter__element-title`}>
                                {category.name}

                            </p>
                        </div>
                    )
                }
            </div>
            <div className="filter__container">
                <p className="filter__heading">
                    Цвет
                </p>
                <div className="filter__color-container">
                {
                    colorsCollection.map(color => 
                        <div key={color.id}
                        onClick={() => setColorCheckedStatus(color)}
                        className={`filter__color-element filter__color-element-selected-${colorChecked.includes(color)}`}
                            style={{background: colorToCSSAdapter.convertForwardToBackwardEntity(color.name)}}>
                        </div>
                    )
                }
                </div>
            </div>
            {/*<div className="filter__container">
                <p className="filter__heading">
                    Производство
                </p>
                {
                    productionCollection.map(production => 
                        <div key={production.id} className={`filter__element-container`}>
                            <Checkbox
                            sx={{
                                color: brown[800],
                                '&.Mui-checked': {
                                  color: brown[800],
                                },
                              }}
                            onChange={() => setProductionCheckedStatus(production)} checked={productionChecked.includes(production)} className={`filter__color-element-checkbox filter__color-element-checkbox-selected-${productionChecked.includes(production)}`}/>
                            <p className={`filter__element-title`}>
                                {production.name}

                            </p>
                        </div>
                    )
                }
            </div>*/}
            {/*<div className="filter__container">
                <p className="filter__heading">
                    Материал
                </p>
                {
                    leatherCollection.map(leather => 
                        <div key={leather.id} className={`filter__element-container`}>
                            <Checkbox
                            sx={{
                                color: brown[800],
                                '&.Mui-checked': {
                                  color: brown[800],
                                },
                              }} 
                            onChange={() => setLeatherCheckedStatus(leather)} checked={leatherChecked.includes(leather)} className={`filter__color-element-checkbox filter__color-element-checkbox-selected-${leatherChecked.includes(leather)}`}/>
                            <p className={`filter__element-title`}>
                                {leather.name}

                            </p>
                        </div>
                    )
                }
            </div>*/}
            <div className="filter__container">
                <p className="filter__heading">
                    Бюджет, BYN
                </p>
                <div className="filter__element-container">
                    <Slider
                        sx={{
                                    color: brown[800],
                                    '&.Mui-checked': {
                                    color: brown[800],
                                    },
                        }}                 
                        getAriaLabel={() => 'Temperature range'}
                        value={moneyRange}
                        onChange={handleMoneyRangeChange}
                        valueLabelDisplay="on"
                        max={1000}
                        //getAriaValueText={valuetext}
                    />
                </div>
                
            </div>

            <button onClick={() => setFilter()} className="filter__accept-btn">
                применить фильтр
            </button>
        </div>
    );
}