export const colorsCollection = [
    {name:"Бежевый", id: 25},
    {name:"Белый", id: 24},
    {name:"Жёлтый", id: 27},
    {name:"Зелёный", id: 26},
    {name:"Коричневый", id: 22},
    {name:"Красный", id: 20},
    {name:"Оранжевый", id: 29},
    {name:"Серый", id: 28},
    {name:"Синий", id: 23},
    {name:"Фиолетовый", id: 30},
    {name:"Чёрный", id: 21},
]
