import { LineItem } from "../types/line-items.type"
import { ShippingCartProduct } from "../types/shipping-cart-product.type"
import { Adapter } from "./interface/adapter.type"

const convertForwardToBackwardEntity = (products: ShippingCartProduct[]): LineItem[] => {
    return products.map(product => {return {product_id: product.id, quantity: product.quantity}})
}

export const productToLineItemsListAdapter:Adapter<ShippingCartProduct[], LineItem[]> = {
    convertForwardToBackwardEntity
}