import { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux";

import { ProductViewModel } from "../../../../models/types/product-view-model.type";
import { categorySelectors } from "../../../../store/categories/categories.selector";
import './ProductCard.scss';
import { Link } from "react-router-dom";

export const ProductCard:FC<ProductViewModel> = (params) => {
    const {product, isTopSeller, isOnSale, isNewProduct, isAvailable} = params;

    const calculateDiscount = (): number => {
        return Math.trunc((1 - (Number(product.sale_price)/Number(product.regular_price)))*100);
    }

    /*useEffect(() => {
        dal.fetchPicture(product.images[0].src).then(response => setPic(response));
        return;
    })*/
    
    return(
        <Link to={`/products/${product.sku}`} className={`product-card__container`}>
            <div className="product-card__tag-container">
                {
                    isTopSeller ? <div className="product-card__tag-element top">ХИТ</div> : null
                }
                {
                    isOnSale ? <div className="product-card__tag-element sale">Акция</div> : null
                }
                {
                    isNewProduct ? <div className="product-card__tag-element new">Новинка</div> : null
                }
                {
                    product.sale_price ? <div className="product-card__tag-element discount">Скидка {calculateDiscount()}%</div> : null
                }
            </div>
            <div className="product-card__img-container">
                <img className="product-card__img" src={product.images[0].src} />

            </div>
            {/*<h3 className="product-card__heading">
                {product.name + ` ${product.sku}`}
            </h3>*/}
            <h3 className="product-card__heading">
                {product.name}
            </h3>
            <p className="product-card__sku">
                Артикул:{product.sku}
            </p>
            { isAvailable ? 
                <div className="product-card__price-container">
                    {
                        isOnSale ? <p className="product-card__price-old">{product.regular_price}</p> : null
                    }
                    <p>
                        {
                            isOnSale ? <p className="product-card__price-new">{product.sale_price} BYN/шт</p> : <p className="product-card__price-new">{product.price} BYN/шт</p>
                        }
                    </p>
                </div>:
                <div className="product-card__price-container">
                    <p className="product-card__price-out">
                        Нет в наличии
                    </p>
                </div>
            }
        </Link>
    )
}