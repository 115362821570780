import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchTags } from "./tags.thunk";
import { LoadingStatus } from "../../models/enums/loading-status.enum";
import { TagDTO } from "../../models/types/tag-dto.type";

export const TagsAdapter = createEntityAdapter<TagDTO>();

export const TagsSlice = createSlice({
    name: 'Tags',
    initialState: TagsAdapter.getInitialState({
        loadingStatus: LoadingStatus.Idle,
    }),
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchTags.fulfilled, (state, action: PayloadAction<TagDTO[]>) => {
            TagsAdapter.setAll(state, action.payload);
            state.loadingStatus = LoadingStatus.Fullfiled;
        })
        .addCase(fetchTags.rejected, (state) => {
            state.loadingStatus = LoadingStatus.Rejected;
        })
        .addCase(fetchTags.pending, (state) => {
            state.loadingStatus = LoadingStatus.Pending;
        })
    }
});

export const TagsActions = TagsSlice.actions;
export default TagsSlice.reducer;