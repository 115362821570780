import { FC, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import './Product.scss'
import { ProductViewModel } from "../../models/types/product-view-model.type";
import { bl } from "../../BL/BL";
import { productDtoToViewModeladapter } from "../../models/adapters/product-dto-to-ciew-model.adapter";
import { colorToCSSAdapter } from "../../models/adapters/color-to-css.adapter";
import { useSelector } from "react-redux";
import { shippingCartSelectors } from "../../store/shipping-cart/shipping-cart.selector";
import { useAppDispatch } from "../../store/store.hooks";
import { ShippingCartActions } from "../../store/shipping-cart/shipping-cart.slice";
import { DimensionsDTO } from "../../models/types/dimnsions-dto.type";
import { ReactComponent as AvailableSvg } from "../../local/svg/product/available.svg";
import { ReactComponent as UnavailableSvg } from "../../local/svg/product/unavailable.svg";
import { ReactComponent as DeliverySvg } from "../../local/svg/product/rb-delivery.svg";
import { ReactComponent as PlusSvg } from "../../local/svg/product/plus.svg";
import { ReactComponent as MinusSvg } from "../../local/svg/product/minus.svg";
import {
    MouseParallaxChild,
    MouseParallaxContainer
  } from "react-parallax-mouse";

import ContentLoader from "react-content-loader";
import { ShippingCartProduct } from "../../models/types/shipping-cart-product.type";
import { ProductCard } from "../Products/List/ProductCard/ProductCard";
import { ProductsActions } from "../../store/products/products.slice";


export const Product:FC = () => {
    const {sku} = useParams();
    const dispatch = useAppDispatch();

    const elementRef = useRef<HTMLImageElement>(null);

    const [width, setWidth] = useState(window.innerWidth);

    const shippingCart = useSelector(shippingCartSelectors.selectShippingCart);
    const [shippingCartProduct, setShippingCartProduct] = useState<ShippingCartProduct | undefined>(undefined);
    const [quantity, setQuantity] = useState<number>(0);
    const [currentParentCategoryName, setCurrentParentCategoryName] = useState<string | undefined>(undefined);

    const [currentProduct, setCurrentProduct] = useState<ProductViewModel | undefined>(undefined);
    const [linkedProducts, setLinkedProducts] = useState<ProductViewModel[]>([] as ProductViewModel[]);
    const [targetImg, setTargetImg] = useState<string>('');
    const [selectedImgChangedStatus, setSelectedImgChangedStatus] = useState<boolean>(false);

    const [topProducts, setTopProducts] = useState<ProductViewModel[]>([] as ProductViewModel[]);

    const [productInformationStatus, setProductInformationStatus] = useState<boolean>(true);
    const [shippingCartOpenedWindowStatus, setShippingCartOpenedWindowStatus] = useState<boolean>(false);
    const [thereIsNoSuchProductStatus, setThereIsNoSuchProductStatus] = useState<boolean>(false);
    const [messageSended, setMessageSendedStatus] = useState<boolean>(false);
    
    const [cursorOnImgStatus, setCursorOnImgStatus] = useState<boolean>(false);

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');

    const onMouseMoveEvent = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        const targetElement = elementRef.current;
        (targetElement as HTMLImageElement).style.transform = `translateX(${100 - event.clientX*0.6}px) translateY(${300 - event.clientY*0.6}px)`;

    }

    const setTargetImgAndImgStatus = (imgSrc: string) => {
        setSelectedImgChangedStatus(true);
        setTimeout(() => setTargetImg(imgSrc), 100);
    }

    const setCurrentProductInfo = (product: ProductViewModel ) => {
        setCurrentProduct(product);
        setTargetImg(product.product.images[0].src);
    }

    const clearCurrentProductInfo = () => {
        setShippingCartProduct(undefined);
        setQuantity(0);
        setCurrentProduct(undefined);

    }

    const clearForm = () => {
        setPhoneNumber('');
        setEmail('');
        setName('');
    }

    useEffect(() =>  {
        if(!cursorOnImgStatus && elementRef.current)
            (elementRef.current as HTMLImageElement).style.transform = `translateX(0px) translateY(0px)`;
    },[cursorOnImgStatus])
    
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
      }
    
    useEffect(() => {
        // component is mounted and window is available
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        // unsubscribe from the event on component unmount
        return () => window.removeEventListener('resize', handleWindowResize);
      }, []);

    useEffect(() => {
        if(selectedImgChangedStatus) 
            setTimeout(() => setSelectedImgChangedStatus(false), 100)
    }, [selectedImgChangedStatus]);

    useEffect(() => {
        setCurrentProduct(undefined);
        setLinkedProducts([]);
        window.scrollTo({ top: -1000, behavior: "smooth" });
        bl.fetchProductBySku(sku as string)
        .then(products => { if(products.length > 0) { 
                products.map(product => {
                    setCurrentProductInfo(productDtoToViewModeladapter.convertForwardToBackwardEntity(product));
                    setCurrentParentCategoryName(product.categories[product.categories.length -1].name ?? undefined);
                    setTargetImg(product.images[0].src);
                    let shippingCarttargetProduct = shippingCart.find(target => target.id === product.id);
                    setQuantity(shippingCarttargetProduct ? shippingCarttargetProduct.quantity : 0)
                    setShippingCartProduct(shippingCarttargetProduct);
                    bl.fetchProductsByTag(product.tags[0]).then(products => setLinkedProducts(products.map(product => productDtoToViewModeladapter.convertForwardToBackwardEntity(product))) );
                    bl.fetchRecomendedProducts().then(products => setTopProducts(products.map(product => productDtoToViewModeladapter.convertForwardToBackwardEntity(product))));
                });
            } else {
                setThereIsNoSuchProductStatus(true);
            }
        })

    },[sku]);

    const pressAddToCartButton = () => {
        addToCart();
        setShippingCartOpenedWindowStatus(true);
    }

    const addToCart = () => {
        if(shippingCartProduct) {
            let product = shippingCartProduct;
            product.quantity += 1;
            setQuantity(product.quantity);
            setShippingCartProduct(product);
            dispatch(ShippingCartActions.incrementProductfromCart(shippingCartProduct));
        } else {
            setQuantity(1);
            setShippingCartProduct(bl.setProductToCartModel(currentProduct as ProductViewModel));
            dispatch(ShippingCartActions.addProductToCart(bl.setProductToCartModel(currentProduct as ProductViewModel)));
        }
    }

    
    const deleteShippingCartProduct = () => {
        dispatch(ShippingCartActions.removeProductfromCart(shippingCartProduct as ShippingCartProduct));
        setShippingCartProduct(undefined);
        setQuantity(0);
    }

    const incrementProduct = (increment: boolean) => {
        let product = shippingCartProduct;

        if(quantity == 1 && !increment) {
            deleteShippingCartProduct();
        } else {
            (product as ShippingCartProduct).quantity = increment ?  (product as ShippingCartProduct).quantity + 1 : (product as ShippingCartProduct).quantity -1;
            setQuantity((product as ShippingCartProduct).quantity);
            setShippingCartProduct(product);
            dispatch(ShippingCartActions.incrementProductfromCart(product as ShippingCartProduct));        
        }
    }


    const setProductXYZspecsToString = (dimensions: DimensionsDTO) => {
        return bl.setProductXYZspecsToString(dimensions);
    }

    const sendFastPurchaseMail = (event:any) => {
        event.preventDefault();
        //bl.sendTelegrammFastPurchaseMessage(bl.buildTelegrammFastPurchaseMessage(phoneNumber, currentProduct?.product.sku as string));
        //setMessageSendedStatus(true);
        setPhoneNumber('');
    }

    const sendSubscribeMail = (event:any) => {
        setShippingCartOpenedWindowStatus(false);
        event.preventDefault();
        bl.sendTelegrammSubscriberMessage(bl.buildTelegrammSubscriberMessage(name, email, phoneNumber, currentProduct?.product.sku as string));
        setMessageSendedStatus(true);
        clearForm();
    }

    const setDefaultFilter = () => {
        dispatch(ProductsActions.clearProductsList());
        //dispatch(ProductsActions.setProductDefaultCategory());
        dispatch(ProductsActions.setDefaultFilter());
        dispatch(ProductsActions.setReloadStatus(true));
    }

    

    return(
        
        <section className={`product__page-container product__page-container-modal-open-${shippingCartOpenedWindowStatus}`}>
            
            {
                thereIsNoSuchProductStatus ?
                <div className="product__bad-request-container">
                    <p className="product__bad-request-element">Данного товара не существует</p>
                    <p className="product__bad-request-element">Возможно вас заинтересуют другие в нашем <Link to='/products' className="product__bad-request-link">каталоге</Link></p>
                </div>:
                <div className={`product__container`}>
                    <p className="catalog__category-heading">
                        <Link to="/" className="info__main-link">Главная</Link> - <Link to="/products" onClick={() => setDefaultFilter()} className="info__main-link">Каталог</Link> - {`${currentParentCategoryName ?? "Изделие из кожи"}`}
                    </p>
                    <h2 className="product__heading">
                        { currentProduct ? (currentProduct as ProductViewModel).product.name : 
                            <ContentLoader
                            className="catalog__content-loader"
                            speed={1}
                            width={800}
                            height={30}
                            backgroundColor="#6b6b6b1c"
                            foregroundColor="#6b6b6b3b"
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="800" height="30" />
                            </ContentLoader>                
                        }
                    </h2>
                    { currentProduct ?
                    <div className="product__data">

                        <div className="product__img-container">
                            <div className="product__img-list">
                                {
                                    (currentProduct as ProductViewModel).product.images.map(img => 
                                    <img className={`product__img-element product__img-element-selected${ targetImg == img.src ? `-true` : `-false`}`} key={img.id} src={img.src} onClick={() => setTargetImgAndImgStatus(img.src)}/>)
                                }
                            </div>
                            <div onMouseMove={event => {if(cursorOnImgStatus && width >= 1100) onMouseMoveEvent(event) } }  onMouseEnter={() => setCursorOnImgStatus(true)} onMouseLeave={() => setCursorOnImgStatus(false)} className="product__img-selected">
                                    <img ref={elementRef} src={targetImg} className={`product__img-selected-pic product__img-selected-pic-${selectedImgChangedStatus}`}/>
                            </div>
                        </div>
                        
                        <div className="product__info-container">
                            <p className="product__sku-container">
                                Артикул:{(currentProduct as ProductViewModel).product.sku}
                            </p>
                            <div className="product__color-container">
                                <p className="product__color-title">
                                    Цвет
                                </p>
                                <div className="product__color-list">
                                    {
                                        linkedProducts.map(product => 
                                            <Link 
                                            onClick={() => {
                                                clearCurrentProductInfo();
                                            }}
                                            className={`product__color-list-element product__color-list-element-selected-${ product.id === currentProduct.id ? `true` : `false`}`}
                                            key={product.id}
                                            to={`/products/${product.product.sku}`} 
                                            style={{background: colorToCSSAdapter.convertForwardToBackwardEntity(product.product.attributes.find(attribute => attribute.slug === "pa_color")?.options[0] as string)}} />)
                                    }
                                </div>
                            </div>
                            {/*<p className="product-card__price-placeholder">Цена будет объявлена скоро</p>*/}
                            <div className="product__price-container">
                                {
                                    (currentProduct as ProductViewModel).isOnSale ? <p className="product__price-old">{(currentProduct as ProductViewModel).product.regular_price}</p> : null
                                }
                                <p>
                                    {
                                        (currentProduct as ProductViewModel).isOnSale ? <p className="product__price-new">{(currentProduct as ProductViewModel).product.sale_price} BYN/шт</p> : <p className="product-card__price-new">{(currentProduct as ProductViewModel).product.price} BYN/шт</p>
                                    }
                                </p>
                            </div>
                            <button className="product__to-cart-btn" disabled={!(currentProduct as ProductViewModel).isAvailable} onClick={() => pressAddToCartButton()}>В корзину</button>
                            {
                                !(currentProduct as ProductViewModel).isAvailable ? <button onClick={() => setShippingCartOpenedWindowStatus(true)} className="product__subscribe">
                                    Уведомить о появлении
                                </button>:null
                            }
                            <div className="product__filter-info-container">
                                <p className={`product__filter-info-element product__filter-info-specs-selected-${productInformationStatus}`} onClick={() => setProductInformationStatus(true)}>
                                    Характеристики
                                </p>
                                <p className={`product__filter-info-element product__filter-info-delivery-selected-${productInformationStatus}`} onClick={() => setProductInformationStatus(false)}>
                                    Доставка и оплата
                                </p>
                            </div>
                            {
                                productInformationStatus ? 
                                <div className="product__specs-container">
                                    
                                    <div className="product__attributes-container">
                                        <p className="product__attributes-element">
                                            {setProductXYZspecsToString((currentProduct as ProductViewModel).product.dimensions)}
                                        </p>
                                        {
                                            (currentProduct as ProductViewModel).product.attributes.map(attribute => 
                                            <p key={attribute.id} className="product__attributes-element">
                                                {attribute.name} - {attribute.options[0] ?? ''}
                                            </p>)
                                        }
                                        <p className="product__attributes-element">
                                            Цена действительна только для интернет-магазина и может отличаться от цен в розничных магазинах
                                        </p>
                                    </div>
                                    <div className="product__delivery-container">
                                        Наличие: {(currentProduct as ProductViewModel).isAvailable ? `Есть ` : `Нет `}
                                        {(currentProduct as ProductViewModel).isAvailable ? <AvailableSvg className="product__delivery-svg"/> : <UnavailableSvg className="product__delivery-svg" />}
                                    </div>
                                    <div className="product__contacts-container">
                                        Наличие в магазинах уточняйте по номеру телефона:
                                        <div className="product__number-container">
                                            <p className="product__number-element">
                                                ул. Островского,5 <br/>
                                                ТЦ “ Планета Green”: <br/>
                                                8(022) 42-60-01
                                            </p>
                                            <p className="product__number-element">
                                                ул.Крыленко,9:  <br/>
                                                8(022) 73-63-42 
                                            </p>
                                        </div>
                                    </div>


                                </div>:
                                <div className="product__payment-info-container">

                                    <p className="product__payment-info-heading">
                                        Оплачивайте покупки удобным способом. В интернет-магазине доступно несколько вариантов оплаты:
                                    </p>
                                    <ol className="product__payment-list-container">
                                        <li className="product__payment-list-element">
                                            Наличные при самовывозе или наложенным платежом при доставке почтой.
                                        </li>
                                        <li className="product__payment-list-element">
                                            Оплата картой рассрочки при самовывозе. Карта рассрочки – это пластиковая карта, которая даёт возможность приобретать товары в кредит без процентов по нему и первоначального взноса (Карта «Халва» - 2 месяца, «Карта покупок» - 3 месяца, Карта «Smart» - 4 месяца, Карта «Магнит» - 3 месяца). При оплате картой рассрочек скидки сохраняются.
                                        </li>
                                    </ol>
                                    <p className="product__payment-info-heading">
                                        Экономьте время на получении заказа. В интернет-магазине доступно несколько вариантов доставки:
                                    </p>
                                    <ol className="product__payment-list-container">
                                        <li className="product__payment-list-element">
                                            Самовывоз из магазина. Список торговых точек для выбора появится в корзине. Когда заказ поступит на склад, вам придет уведомление. Для получения заказа обратитесь к сотруднику в кассовой зоне и назовите номер.
                                        </li>
                                        <li className="product__payment-list-element">
                                            Почтовая доставка через почту Республики Беларусь. Когда заказ придет в отделение, на ваш адрес придет извещение о посылке. Перед оплатой вы можете оценить состояние коробки: вес, целостность. Вскрывать коробку самостоятельно вы можете только после оплаты заказа.
                                        </li>
                                    </ol>
                                </div>
                            }

                            {
                                (currentProduct as ProductViewModel).isAvailable ? 
                                    <div className="product__fast-payment-container">
                                        <p className="product__fast-payment-heading">
                                        <DeliverySvg className="product__fast-payment-heading-svg" /> Доставка: РБ 
                                        </p>
                                        <div className="product__fast-payment-form-container">
                                            <p className="product__fast-payment-label">
                                                Сделайте заказ в 1 клик
                                            </p>
                                            <form onSubmit={event => sendFastPurchaseMail(event)} className="product__fast-payment-form-input-container">
                                                <input type="tel" required value={phoneNumber} className="product__fast-payment-form-input" onChange={event => setPhoneNumber(event.target.value)} placeholder="+375 __ ___— __— __" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
                                                <button type="submit" className="product__fast-payment-form-btn">Заказать</button>
                                            </form>
                                        </div>
                                    </div>:null
                            }
                            
                        </div>



                        { 
                            !(currentProduct as ProductViewModel).isAvailable ?
                                <div className={`product__subscribe-bkg product__subscribe-bkg-status-${shippingCartOpenedWindowStatus}`}>
                                    <div className="product__subscribe-container">
                                        <button className="product__subscribe-close" onClick={() => setShippingCartOpenedWindowStatus(false)}>
                                            <UnavailableSvg className="product__subscribe-svg" />
                                        </button>
                                        <p className="product__subscribe-heading">
                                            Уведомить о появлении
                                        </p>
                                        <form className="product__subscribe-form-container" onSubmit={(event) => sendSubscribeMail(event)}>
                                            <input value={name} onChange={(event) => setName(event.target.value)} required minLength={1} className="product__subscribe-input product__subscribe-input-name" placeholder="ФИО" type="text" />
                                            <div className="product__subscribe-input-container">
                                                <input value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} required placeholder="+375 XX XXX-XX-XX" className="product__subscribe-input product__subscribe-input-phone" type="tel" pattern="^[0-9+-]{7,}"/>
                                                <input value={email} onChange={(event) => setEmail(event.target.value)} required placeholder="email@domen.com" className="product__subscribe-input product__subscribe-input-mail" type="email" pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}"/>
                                            </div>
                                            <button className="product__subscribe-input-btn" type="submit">Отправить заявку</button>
                                        </form>
                                    </div>
                                </div>:
                                    <div className={`product__cart-bkg product__cart-bkg-status-${shippingCartOpenedWindowStatus}`}>
                                        <div className="product__cart-container">
                                            <p className="product__cart-heading">
                                                Товар добавлен в корзину
                                            </p>
                                            {   shippingCartProduct ?
                                                <div className="product__cart-product-container">

                                                    <div className="product__cart-product-img-container">
                                                        <img className="product__cart-product-img" src={shippingCartProduct.product.images[0].src} />

                                                    </div>
                                                    <div className="product__cart-product-description-container">

                                                        <div className="product__cart-product-title-container">
                                                            <p className="product__cart-product-title-name">
                                                                {shippingCartProduct.product.name}
                                                            </p>
                                                            <p className="product__cart-product-title-sku">
                                                                Артикул:{shippingCartProduct.product.sku}
                                                            </p>
                                                        </div>

                                                        <div className="product__cart-product-quantity-container">
                                                            <p className="product__cart-product-quantity-heading">
                                                                количество
                                                            </p>
                                                            <div className="product__cart-product-controls-container">
                                                                <button className="product__cart-product-controls-element" onClick={() => incrementProduct(false)}><MinusSvg className="product__cart-product-controls-svg product__cart-product-controls-svg-minus"/></button>
                                                                <p className="product__cart-product-controls-quantity">{quantity}</p>
                                                                <button className="product__cart-product-controls-element" onClick={() => incrementProduct(true)}><PlusSvg className="product__cart-product-controls-svg product__cart-product-controls-svg-plus" /></button>
                                                            </div>
                                                        </div>
                                                        <div className="product__cart-product-price-container">
                                                        {shippingCartProduct.product.price} BYN/шт
                                                        </div>

                                                    </div>
                                                    <button className="product__cart-close">
                                                        <UnavailableSvg className="product__cart-svg" onClick={() => deleteShippingCartProduct()}/>
                                                    </button>
                                                </div>: 
                                                <div className="product__cart-product-container">
                                                    <p className="product__cart-product-title-name">
                                                        Товар был удалён из корзины
                                                    </p>
                                                    <button className="product__cart-back" onClick={() => addToCart()}>
                                                        вернуть
                                                    </button>
                                                </div>
                                            }
                                            <Link className="product__cart-btn-cart-link" to='/shipping-cart'>Перейти в корзину</Link>
                                            <button className="product__cart-btn-close" onClick={() => setShippingCartOpenedWindowStatus(false)}>Вернуться к покупкам</button>
                                        </div>
                                    </div>
                        }

                    <div className={`product__message-sended-bkg product__message-sended-bkg-status-${messageSended}`}>
                        <div className="product__message-sended-container">
                            <button className="product__message-sended-close" onClick={() => setMessageSendedStatus(false)}>
                                <UnavailableSvg className="product__message-sended-svg" />
                            </button>                                
                            <p className="product__message-sended-text">
                                Заявка отправлена - спасибо
                            </p>

                        </div>
                    </div>



                    </div>: 
                        <ContentLoader
                            className="catalog__content-loader"
                            speed={1}
                            width={1000}
                            height={1000}
                            backgroundColor="#6b6b6b1c"
                            foregroundColor="#6b6b6b3b"
                            >
                                <circle cx="50" cy="50" r="50" />
                                <circle cx="50" cy="200" r="50" />
                                <circle cx="50" cy="350" r="50" />
                                <circle cx="350" cy="200" r="200" />
                                <rect x="800" y="0" rx="8" ry="8" width="200" height="20" />
                                <rect x="800" y="50" rx="8" ry="8" width="200" height="20" />
                                <rect x="800" y="100" rx="8" ry="8" width="200" height="20" />
                                <rect x="800" y="150" rx="8" ry="8" width="200" height="20" />
                                <rect x="800" y="200" rx="8" ry="8" width="200" height="20" />
                        </ContentLoader>                
                    }

                        {topProducts.length !==0 ?<p className="product__color-title product__adittional">
                            Вам может понравиться

                        </p>: null}

                        <div className="main-page__top-products-cards-container">
                        {
                            topProducts.length !==0 ? topProducts.map(product => <ProductCard key={product.id} {...product} />) : 
                            <ContentLoader
                            className="catalog__content-loader"
                            speed={1}
                            width={250}
                            height={400}
                            backgroundColor="#6b6b6b1c"
                            foregroundColor="#6b6b6b3b"
                            >
                                <circle cx="125" cy="110" r="80" />
                                <rect x="25" y="230" rx="8" ry="8" width="300" height="20" />
                                <rect x="25" y="270" rx="8" ry="8" width="100" height="25" />
                            </ContentLoader>
                        }
                        </div>

                </div>
            }


    
                        
        </section>
    );
}