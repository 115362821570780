import { FC, useState } from "react";
import './DropDown.scss';
import { DropDownParams } from "../../../models/types/dropdown-main-page-params.type";
import { ReactComponent as PlusSvg } from "../../../local/svg/drop-down/plus.svg";
import { ReactComponent as MinusSvg } from "../../../local/svg/drop-down/minus.svg";

export const DropDown:FC<DropDownParams> = (params) => {
    const {question, answer} = params;
    const [dropDownOpenStatus, setDropDownOpenStatus] = useState<boolean>(false);

    return(
        <div className={`drop-down__container drop-down__container-opened-${dropDownOpenStatus}`}>
                <h3 className="drop-down__heading">{question}</h3>
                <p className="drop-down__text">
                    {answer}
                </p>
                <button className="drop-down__btn" onClick={() => setDropDownOpenStatus(!dropDownOpenStatus)}>
                    <PlusSvg className={`drop-down__element drop-down__element-first drop-down__element-first-opened-${dropDownOpenStatus}`} />
                    <MinusSvg className={`drop-down__element drop-down__element-second drop-down__element-second-opened-${dropDownOpenStatus}`} />
                </button>
        </div>
    );
}