import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const OrderInfoSlice = createSlice({
    name: 'OrderInfo',
    initialState: {
        first_name: "",
        last_name: "",
        address_1: "",
        email: "",
        phone: ""
    },
    reducers: {
        setFirstName(state, action: PayloadAction<string>) {
            state.first_name = action.payload;
        },
        setLastName(state, action: PayloadAction<string>) {
            state.last_name = action.payload;
        },
        setAdress(state, action: PayloadAction<string>) {
            state.address_1 = action.payload;
        },
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        setPhone(state, action: PayloadAction<string>) {
            state.phone = action.payload;
        },
    }
});

export const OrderInfoActions = OrderInfoSlice.actions;
export default OrderInfoSlice.reducer;