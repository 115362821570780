
import './App.scss';
import { Header } from './Header/Header';
import { BrowserRouter, createBrowserRouter, Route, Routes } from 'react-router-dom';
import { Products } from './Products/Products';
import { MainPage } from './MainPage/MainPage';
import { Footer } from './Footer/Footer';
import { Product } from './Product/Product';
import { ShippingCart } from './ShippingCart/ShippingCart';
import { Info } from './Info/Info';
import { NotFound } from './NotFound/NotFound';
import { Partners } from './Partners/Partners';
import { Sales } from './Sales/Sales';

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    errorElement: <NotFound />,
    children: [
      {
        path: 'products', 
        element: <Products />,
        children: [
          {
            path:':sku', 
            element:<Product />
          }
        ]
      },
      {
        path:'shipping-cart', 
        element:<ShippingCart />
      },
      {
        path:'info', 
        element:<Info  />
      },
    ]
  },
]);

function App() {
  
  return (
    <div className="app">

      <Header />
        <Routes>
          <Route path='/' element={<MainPage />} />
            <Route path='products' element={<Products />} />
              <Route path='products/:sku' element={<Product />} />
            <Route path='shipping-cart' element={<ShippingCart />} />
            <Route path='info' element={<Info />} />
            <Route path='partners' element={<Partners />} />
            <Route path='sales' element={<Sales />} />
          <Route path='*' element={<NotFound />} />
        </Routes>

      <Footer />
    </div>
  );
}

export default App;
