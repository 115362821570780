import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchCategories } from "./categories.thunk";
import { LoadingStatus } from "../../models/enums/loading-status.enum";
import { CategoryDTO } from "../../models/types/category-dto.type";

export const CategoriesAdapter = createEntityAdapter<CategoryDTO>();

export const CategoriesSlice = createSlice({
    name: 'Categories',
    initialState: CategoriesAdapter.getInitialState({
        loadingStatus: LoadingStatus.Idle,
    }),
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCategories.fulfilled, (state, action: PayloadAction<CategoryDTO[]>) => {
            CategoriesAdapter.setAll(state, action.payload);
            state.loadingStatus = LoadingStatus.Fullfiled;
        })
        .addCase(fetchCategories.rejected, (state) => {
            state.loadingStatus = LoadingStatus.Rejected;
        })
        .addCase(fetchCategories.pending, (state) => {
            state.loadingStatus = LoadingStatus.Pending;
        })
    }
});

export const CategoriesActions = CategoriesSlice.actions;
export default CategoriesSlice.reducer;