import { FC, useEffect } from "react";
import './Info.scss';
import { ReactComponent as InstagramSvg} from '../../local/svg/info/instagramm.svg';
import { ReactComponent as PhoneSvg} from '../../local/svg/info/phone.svg';
import { ReactComponent as MailSvg} from '../../local/svg/info/mail.svg';
import { Placemark, YMaps, Map as YMapTarget } from '@pbe/react-yandex-maps';
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store/store.hooks";
import { useSelector } from "react-redux";
import { productsSelectors } from "../../store/products/products.selector";

export const Info:FC = () => {
    return(
        <section className="info__container">
            <h2 className="catalog__category-heading">
                <Link to="/" className="info__main-link">Главная</Link> - Информация
            </h2>
            <div className="info__block-container">
                <h3 className="info__block-heading" id="delivery">
                    Условия доставки
                </h3>
                <div className="info__block-description-conatiner">
                    <p className="info__block-description-element">
                        Доставка товаров осуществляется РУП“ Белпочта”  за счет средств производителя.  
                    </p>
                </div>
            </div>
            <div className="info__block-container">
                <h3 className="info__block-heading" id="refund">
                    Условия возврата
                </h3>
                <div className="info__block-description-conatiner">
                    <p className="info__block-description-element">
                    Возврат качественного товара осуществляется в случае, если:<br/>
                    – товар не был в употреблении;<br/>
                    – был сохранен товарный вид и потребительские свойства;<br/>
                    – с момента приобретения прошло не более 14 дней.              
                    </p>
                </div>
            </div>
            <div className="info__block-container">
                <h3 className="info__block-heading" id="payment">
                    Реквизиты для оплаты
                </h3>
                <div className="info__block-description-conatiner">
                    <p className="info__block-description-element">
                        ООО «Рембай Групп»<br/>
                        УНП 790758928 ОКПО 299899317000<br/>
                        212012 Республика Беларусь, г. Могилев, ул. Челюскинцев, 155Г<br/>
                        р/с BY74ALFA30122C45250010270000 в ЗАО «Альфа-Банк»<br/>
                        г. Минск, ул. Сурганова, 43-47<br/>
                        БИК ALFABY2X
                    </p>
                </div>
            </div>
            <div className="info__block-container">
                <h3 className="info__block-heading" id="gift">
                    Порядок и условия реализации товаров по подарочному сертификату
                </h3>
                <div className="info__block-description-conatiner">
                    <p className="info__block-description-element">
                    – Срок действия подарочного сертификата – 1месяц<br/>
                    – ООО « Рембай Групп» установлено, что сдача по подарочным сертификатам не производится. Если стоимость товаров больше номинальной цены подарочного сертификата, то потребитель доплачивает недостающую сумму.<br/>
                    – ООО « Рембай Групп» вправе отказать потребителю в передаче товаров (выполнении работ, оказании услуг) по истечении срока сертификата. Деньги за сертификат в данном случае потребителю не возвращаются.<br/>
                    – Товары надлежащего качества, приобретенный по сертификату ,не подлежат обмену и возврату.<br/>
                    – Сертификат можно приобрести только в наших фирменных магазинах<br/>
                    </p>
                </div>
            </div>
            <div className="info__block-container">
                <h3 className="info__block-heading" id="contacts">
                    Контакты
                </h3>
                <div className="info__block-svg-container">
                    <div className="info__block-svg-element">
                        <InstagramSvg />
                        <a href="https://www.instagram.com/rembay.by/">
                            rembay.by
                        </a>
                    </div>
                    <div className="info__block-svg-element">
                        <MailSvg />
                        <a href="mailto:buinichi18@mail.ru">
                            buinichi18@mail.ru
                        </a>
                    </div>
                    <div className="info__block-svg-element">
                        <PhoneSvg className="info__phone" />
                        <p>
                            <a href="tel:+375447665050" className="info__block-svg-link">+375 44 766-50-50</a><br/>
                            <br/>
                            <a href="tel:+375222728291" className="info__block-svg-link">+375 22 272 82 91</a><br/>
                            <a href="tel:+375296494931" className="info__block-svg-link">+375 29 649 49 31 (отдел продаж) </a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="info__block-container">
                <h3 className="info__block-heading" id="destination">
                    Адреса всех магазинов и номера телефонов 
                </h3>
                <div className="info__block-description-conatiner">
                    <p className="info__block-description-element">
                    г.Могилев, ул.Крыленко,9  телефон <b>8(022) 73-63-42</b> <br/>
                    г.Могилев , ул. Островского,5 ТЦ “ Планета Green” телефон <b>8(022) 42-60-01</b><br/>
                    г.Могилев, ул.Белинского ,28 ТЦ “ Гринвич”<br/>
                    </p>
                </div>
            </div>
            <div className="info__block-container">
                <YMaps>
                    <YMapTarget className="info__map-element" state={{ center: [53.908437, 30.345516], zoom: 12}}>
                        <Placemark geometry={[53.875229, 30.333749]} />
                        <Placemark geometry={[53.907809, 30.336622]} />
                        <Placemark geometry={[53.932042, 30.350858]} />
                    </YMapTarget>
                </YMaps>
            </div>
        </section>
    )
}