import { PayloadAction, createEntityAdapter, createSlice, current } from "@reduxjs/toolkit";
import { ProductDTO } from "../../models/types/product-dto.type";
import { fetchProductsPerPageByArgumentsAndCategory } from "./products.thunk";
import { LoadingStatus } from "../../models/enums/loading-status.enum";
import { ProductsState } from "../../models/types/products-state.type";
import { ProductsFilterState } from "../../models/types/filter.type";
import { CategoryDTO } from "../../models/types/category-dto.type";
import { ProductViewModel } from "../../models/types/product-view-model.type";
import { productDtoToViewModeladapter } from "../../models/adapters/product-dto-to-ciew-model.adapter";
import { bl } from "../../BL/BL";
import { AttributeFilterTerm } from "../../models/types/filter-attribute-term.type";

export const ProductsAdapter = createEntityAdapter<ProductViewModel>();

export const ProductsSlice = createSlice({
    name: 'Products',
    initialState: ProductsAdapter.getInitialState<ProductsState>({
        loadingStatus: LoadingStatus.Idle,
        currentPage: 1,
        reload:true,
        empty: false,
        filter: {
            selectedParentCategory: {
                id: 0,
                name: "",
            },
            selectedCategories: [] as CategoryDTO[],
            selectedColor: [] as AttributeFilterTerm[],
            selectedSex: [] as AttributeFilterTerm[],
            selectedLeather: [] as AttributeFilterTerm[],
            selectedProduction: [] as AttributeFilterTerm[],
            searchQuery: '',
            moneyLewelCost: [0,1000],
            widthLewelCost: [0,100],
            heightLewelCost: [0,100],
            lengthLewelCost: [0,1000],
        } as ProductsFilterState,
    }),
    reducers: {
        setDefaultFilter(state) {
            state.filter = {
                selectedParentCategory: {
                    id: 0,
                    name: "",
                },
                selectedCategories: [] as CategoryDTO[],
                selectedColor: [] as AttributeFilterTerm[],
                selectedSex: [] as AttributeFilterTerm[],
                selectedLeather: [] as AttributeFilterTerm[],
                selectedProduction: [] as AttributeFilterTerm[],
                searchQuery: '',
                moneyLewelCost: [0,1000],
                widthLewelCost: [0,100],
                heightLewelCost: [0,100],
                lengthLewelCost: [0,1000],
            } as ProductsFilterState;
        },
        setEmptyStatus(state, action: PayloadAction<boolean>) {
            state.empty = action.payload;
        },
        setReloadStatus(state, action: PayloadAction<boolean>) {
            state.reload = action.payload;
        },
        setSearchQuery(state, action: PayloadAction<string>) {
            state.filter.searchQuery = action.payload;
        },
        addMoneyFilter(state, action: PayloadAction<[number,number]>) {
            state.filter.moneyLewelCost = action.payload;
        },
        addWidthFilter(state, action: PayloadAction<[number,number]>) {
            state.filter.widthLewelCost = action.payload;
        },
        addHeightFilter(state, action: PayloadAction<[number,number]>) {
            state.filter.heightLewelCost = action.payload;
        },
        addLengthFilter(state, action: PayloadAction<[number,number]>) {
            state.filter.lengthLewelCost = action.payload;
        },
        addColor(state, action: PayloadAction<AttributeFilterTerm>) {
            state.filter.selectedColor.push(action.payload);
        },
        removeColor(state , action:PayloadAction<number>) {
            state.filter.selectedColor.splice(state.filter.selectedColor.findIndex((color) => color.id === action.payload),1);
        },
        addSex(state, action: PayloadAction<AttributeFilterTerm>) {
            state.filter.selectedSex.push(action.payload);
        },
        removeSex(state , action:PayloadAction<number>) {
            state.filter.selectedSex.splice(state.filter.selectedSex.findIndex((sex) => sex.id === action.payload),1);
        },
        addLeather(state, action: PayloadAction<AttributeFilterTerm>) {
            state.filter.selectedLeather.push(action.payload);
        },
        removeLeather(state , action:PayloadAction<number>) {
            state.filter.selectedLeather.splice(state.filter.selectedLeather.findIndex((leather) => leather.id === action.payload),1);
        },
        addProduction(state, action: PayloadAction<AttributeFilterTerm>) {
            state.filter.selectedProduction.push(action.payload);
        },
        removeProduction(state , action:PayloadAction<number>) {
            state.filter.selectedProduction.splice(state.filter.selectedProduction.findIndex((production) => production.id === action.payload),1);
        },
        setProductParentCategory(state, action: PayloadAction<CategoryDTO>) {
            state.currentPage = 1;
            state.filter.selectedParentCategory = action.payload;
            state.filter.selectedCategories = [action.payload];
        },
        setProductDefaultCategory(state) {
            state.filter.selectedParentCategory = {
                id: 0,
                name: "",
            } as CategoryDTO;
        },
        addCategory(state, action: PayloadAction<CategoryDTO>) {
            state.filter.selectedCategories.push(action.payload);
        },
        removeCategory(state , action:PayloadAction<number>) {
            if(action.payload === state.filter.selectedParentCategory.id)
                state.filter.selectedParentCategory = {
                    id: 0,
                    name: "",
                } as CategoryDTO;
            state.filter.selectedCategories.splice(state.filter.selectedCategories.findIndex((category) => category.id === action.payload),1);
        },
        clearCategoryList(state) {
            state.filter.selectedCategories = [];
            state.filter.selectedParentCategory = {
                id: 0,
                name: "",
            } as CategoryDTO;
        },
        clearProductsList(state) {
            ProductsAdapter.removeAll(state);
        },
        setPage(state, action: PayloadAction<number>) {
            state.currentPage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchProductsPerPageByArgumentsAndCategory.fulfilled, (state, action: PayloadAction<ProductDTO[]>) => {
            ProductsAdapter.addMany(state, bl.filterProductsForTopSellers(action.payload.map(product => productDtoToViewModeladapter.convertForwardToBackwardEntity(product))));
            state.loadingStatus = LoadingStatus.Fullfiled;
            state.reload = false;
            state.empty = action.payload.length<24 ? true : false
        })
        .addCase(fetchProductsPerPageByArgumentsAndCategory.rejected, (state) => {
            state.loadingStatus = LoadingStatus.Rejected;
            state.reload = false;
            state.empty = true;

        })
        .addCase(fetchProductsPerPageByArgumentsAndCategory.pending, (state) => {
            state.loadingStatus = LoadingStatus.Pending;
        })
    }
});

export const ProductsActions = ProductsSlice.actions;
export default ProductsSlice.reducer;