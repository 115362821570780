import { ProductViewModel } from "../types/product-view-model.type"
import { ShippingCartProduct } from "../types/shipping-cart-product.type";
import { Adapter } from "./interface/adapter.type"

const convertForwardToBackwardEntity = (product: ProductViewModel): ShippingCartProduct => {
    return {id: product.id, product: product.product, quantity: 1} as ShippingCartProduct;
}

export const productViewModelToCartAdapter:Adapter<ProductViewModel, ShippingCartProduct> = {
    convertForwardToBackwardEntity
}