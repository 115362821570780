import { createAsyncThunk } from "@reduxjs/toolkit";
import { dal } from "../../DAL/DAL";
import { CategoryDTO } from "../../models/types/category-dto.type";

export const fetchCategories = createAsyncThunk<CategoryDTO[]>(
    'categories/getCategoriesList',
    async() => {
        const response:CategoryDTO[] = await dal.fetchCategories();
        return response;
    }
)

export const fetchParentCategories = createAsyncThunk<CategoryDTO[]>(
    'categories/getParentCategoriesList',
    async() => {
        const response:CategoryDTO[] = await dal.fetchParentCategories();
        return response;
    }
)

export const fetchChildrenCategories = createAsyncThunk<CategoryDTO[], number>(
    'categories/getChildrenCategoriesList',
    async(parentId) => {
        const response:CategoryDTO[] = await dal.fetchChildCategories(parentId);
        return response;
    }
)