import { createAsyncThunk } from "@reduxjs/toolkit";
import { dal } from "../../DAL/DAL";
import { TagDTO } from "../../models/types/tag-dto.type";

export const fetchTags = createAsyncThunk<TagDTO[]>(
    'tags/getTagsList',
    async() => {
        const response:TagDTO[] = await dal.fetchTags();
        return response;
    }
)