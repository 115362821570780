import axios from "axios";
import { CategoryDTO } from "../models/types/category-dto.type";
import { CouponDTO } from "../models/types/coupon-dto.type";
import { ProductDTO } from "../models/types/product-dto.type"
import { TagDTO } from "../models/types/tag-dto.type";
import { BannerDTO } from "../models/types/banner-dto.type";
import WooCommerceRestApi from "woocommerce-rest-ts-api";
import addOAuthInterceptor from "axios-oauth-1.0a";
import { TopProductsFilter } from "../models/enums/top-products-filters.enum";
import { AttributesDTO } from "../models/types/attributes-dto.type";
import { OrderDTO } from "../models/types/order-dto.type";
import { OrderResponseDTO } from "../models/types/order-response-dto.type";


const bannerUrl = '../assets/banner.json';

const serviceUrl = process.env.REACT_APP_SERVICE_ADRS as string;
const consumerKey = process.env.REACT_APP_SERVICE_API_IK_LOCAL as string;
const consumerSecret = process.env.REACT_APP_SERVICE_API_SK_LOCAL as string;
const version = process.env.REACT_APP_SERVICE_API_VRSN as string;
const tgChatFPApiToken = process.env.REACT_APP_FP_BOT_TOKEN as string;
const tgChatFPGroupKey = process.env.REACT_APP_FP_CHAT_ID as string;
const tgChatSUBApiToken = process.env.REACT_APP_SUB_BOT_TOKEN as string;
const tgChatSUBGroupKey = process.env.REACT_APP_SUB_CHAT_ID as string;
const tgChatORDApiToken = process.env.REACT_APP_ORD_BOT_TOKEN as string;
const tgChatORDGroupKey = process.env.REACT_APP_ORD_CHAT_ID as string;

const telegramApiUri = `https://api.telegram.org/bot`;

const wcapiv3 = new WooCommerceRestApi({
    url: serviceUrl,
    consumerKey: consumerKey,
    consumerSecret: consumerSecret,
    version: "wc/v3",
    queryStringAuth: false, // Force Basic Authentication as query string true and using under HTTPS
    /*axiosConfig: {
        headers: {
            'Access-Control-Allow-Origin' : '*' , 
            //'Authorization': 'Bearer 01101998'
        }
    }*/
  });

const fetchBanners = async ():Promise<BannerDTO[]> => {
    const response = await axios.get(bannerUrl);
    return response.data;
}

const fetchProductsByTag = async (tag: TagDTO):Promise<ProductDTO[]> => {
    //const response = await axiosClient.get(serviceUrl + "/wc-api/" + version + "/products?filter[limit] =-1"/*, {auth: {username: consumerKey, password: consumerSecret}}*/);
    const response = await wcapiv3.get("products",{per_page: 100, tag: String(tag.id)});
    return response.data;
    //return wcapi.get("products");
}

const fetchProductBySku = async (skuParam: string):Promise<ProductDTO[]> => {
    //const response = await axiosClient.get(serviceUrl + "/wc-api/" + version + "/products?filter[limit] =-1"/*, {auth: {username: consumerKey, password: consumerSecret}}*/);
    const response = await wcapiv3.get("products",{per_page: 1, sku: skuParam});
    return response.data;
    //return wcapi.get("products");
}

const fetchTopFiveProducts = async (order: TopProductsFilter):Promise<ProductDTO[]> => {
    //const response = await axiosClient.get(serviceUrl + "/wc-api/" + version + "/products?filter[limit] =-1"/*, {auth: {username: consumerKey, password: consumerSecret}}*/);
    const response = await wcapiv3.get("products",{per_page: 5, page: 1, order_by: order, order: 'desc'});
    return response.data;
    //return wcapi.get("products");
}

const fetchFiveRecomendedProducts = async ():Promise<ProductDTO[]> => {
    //const response = await axiosClient.get(serviceUrl + "/wc-api/" + version + "/products?filter[limit] =-1"/*, {auth: {username: consumerKey, password: consumerSecret}}*/);
    const response = await wcapiv3.get("products",{per_page: 5, page: 1, order_by: 'popularity', order: 'desc'});
    return response.data;
    //return wcapi.get("products");
}

const fetchProduct = async (id: number):Promise<ProductDTO[]> => {
    const response = await axios.get(serviceUrl + "/wp-json/wc/" + version + "/products/" + id, {auth: {username: consumerKey, password: consumerSecret}, headers: {'Access-Control-Allow-Origin' : '*'}/*,'Authorization': 'Bearer 01101998'}*/});
    return response.data;
    //return wcapi.get("products");
}

const fetchSearchedProducts = async (search: string):Promise<ProductDTO[]> => {
    const response = await axios.get(serviceUrl + "/wp-json/wc/" + version + "/products?search=" + search, {auth: {username: consumerKey, password: consumerSecret}, headers: {'Access-Control-Allow-Origin' : '*'}/*,'Authorization': 'Bearer 01101998'}*/});
    return response.data;
    //return wcapi.get("products");
}

const fetchFilteredProductsPerPage = async (args: string,page:number):Promise<ProductDTO[]> => {
    const response = await axios.get(serviceUrl + "/wp-json/wc/" + version + "/products?" + args +`&per_page=24&page=${page}`, {auth: {username: consumerKey, password: consumerSecret}});
    return response.data;
    //return wcapi.get("products");
}

const fetchTags = async ():Promise<TagDTO[]> => {
    const response = await axios.get(serviceUrl + "/wp-json/wc/" + version + "/products/tags?per_page=100", {auth: {username: consumerKey, password: consumerSecret}, headers: {'Access-Control-Allow-Origin' : '*'}/*,'Authorization': 'Bearer 01101998'}*/});
    return response.data;
    //return wcapi.get("tags");
}

const fetchCategories = async ():Promise<CategoryDTO[]> => {
    const response = await axios.get(serviceUrl + "/wp-json/wc/" + version + "/products/categories?per_page=100", {auth: {username: consumerKey, password: consumerSecret}});
    return response.data;
    //return wcapi.get("categories");
}

const fetchChildCategories = async (parentId: number):Promise<CategoryDTO[]> => {
    const response = await axios.get(serviceUrl + "/wp-json/wc/" + version + "/products/categories?per_page=100&parent=" + parentId, {auth: {username: consumerKey, password: consumerSecret}, headers: {'Access-Control-Allow-Origin' : '*'}/*,'Authorization': 'Bearer 01101998'}*/});
    return response.data;
    //return wcapi.get("categories");
}

const fetchParentCategories = async ():Promise<CategoryDTO[]> => {
    const response = await axios.get(serviceUrl + "/wp-json/wc/" + version + "/products/categories?per_page=100&parent=0", {auth: {username: consumerKey, password: consumerSecret}, headers: {'Access-Control-Allow-Origin' : '*'}/*,'Authorization': 'Bearer 01101998'}*/});
    return response.data;
    //return wcapi.get("categories");
}

const setOrder = async (order: OrderDTO):Promise<OrderResponseDTO> => {
    const response = await axios.post(serviceUrl + "/wp-json/wc/" + version + "/orders",order, {auth: {username: consumerKey, password: consumerSecret}/*, headers: {'Access-Control-Allow-Origin' : '*','Authorization': 'Bearer 01101998'}*/});
   //const response = await wcapiv3.post("orders",data = order);
   return response.data;
}

const sendTelegramBotSubscriberMessage = (message: string) => {
    axios.post(telegramApiUri+tgChatSUBApiToken+"/sendMessage", {
        chat_id: tgChatSUBGroupKey,
        text: message
    })

}

const sendTelegramBotFastPurchaseMessage = (message: string) => {
    axios.post(telegramApiUri+tgChatFPApiToken+"/sendMessage", {
        chat_id: tgChatFPGroupKey,
        text: message
    })

    
}

const sendTelegramBotOrderMessage = (message: string) => {
    axios.post(telegramApiUri+tgChatORDApiToken+"/sendMessage", {
        chat_id: tgChatORDGroupKey,
        text: message
    })

    
}

export const dal = {
    setOrder,
    sendTelegramBotOrderMessage,
    sendTelegramBotSubscriberMessage,
    sendTelegramBotFastPurchaseMessage,
    fetchFilteredProductsPerPage,
    fetchProductsByTag,
    fetchProductBySku,
    fetchTopFiveProducts,
    fetchFiveRecomendedProducts,
    fetchSearchedProducts,
    fetchProduct,
    fetchTags,
    fetchCategories,
    fetchChildCategories,
    fetchParentCategories,
    fetchBanners,
}