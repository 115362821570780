import { dal } from "../DAL/DAL";
import { productViewModelToCartAdapter } from "../models/adapters/product-view-to-cart.adapter";
import { PaymentMethodEnum } from "../models/enums/payment-method.enum";
import { TopProductsFilter } from "../models/enums/top-products-filters.enum";
import { BannerDTO } from "../models/types/banner-dto.type";
import { BillingAdress } from "../models/types/billing-adress.type";
import { CategoryDTO } from "../models/types/category-dto.type";
import { DimensionsDTO } from "../models/types/dimnsions-dto.type";
import { AttributeFilterTerm } from "../models/types/filter-attribute-term.type";
import { LineItem } from "../models/types/line-items.type";
import { OrderDTO } from "../models/types/order-dto.type";
import { OrderResponseDTO } from "../models/types/order-response-dto.type";
import { ProductDTO } from "../models/types/product-dto.type"
import { ProductViewModel } from "../models/types/product-view-model.type";
import { ShippingCartProduct } from "../models/types/shipping-cart-product.type";
import { TagDTO } from "../models/types/tag-dto.type"

const isFilterIngredientsIncludesInProduct = (filterIngerdients: TagDTO[] , product: ProductDTO ): boolean => {
    let count = 0;
    while(count < product.tags.length) {
        if(filterIngerdients.includes(product.tags[count])) {
            count++;
        }
        else {
            return false;
        }
    }
    return true;
}

const convertOrderProductsToLineItems = (products: ShippingCartProduct[]): LineItem[] => {
    return convertOrderProductsToLineItems(products);
}

const filterProductsForTopSellers = (products: ProductViewModel[]): ProductViewModel[] => {
    return products.sort((productA, productB) => {
        return (/*productA.product.total_sales > productB.product.total_sales &&*/ productA.isAvailable) ? -1 : 0;
    });
}

const isProductNew = (productCreationDate: string): boolean => {
    const date = new Date();

    const fullCurrentYear: number = date.getFullYear();
    const fullCurrentMonth: number = date.getMonth();
    const fullCurrentDay: number = date.getDay();


    const productMonth: number = Number(productCreationDate.slice(5,7));
    const productYear: number = Number(productCreationDate.slice(0,4));
    const productDay: number = Number(productCreationDate.slice(8,10));

    const difference = (fullCurrentYear - productYear) * 100 + (fullCurrentMonth - productMonth) * 10 + (fullCurrentDay - productDay);
    return difference <= 62 ? true : false;
}

const setProductToCartModel = (product: ProductViewModel) => {
    return productViewModelToCartAdapter.convertForwardToBackwardEntity(product);
}

const fetchTopProductsByOrder = async (args: TopProductsFilter): Promise<ProductDTO[]> => {
    return await dal.fetchTopFiveProducts(args);
}

const fetchRecomendedProducts = async (): Promise<ProductDTO[]> => {
    return await dal.fetchFiveRecomendedProducts();
}

const fetchProductBySku = async (sku: string): Promise<ProductDTO[]> => {
    return await dal.fetchProductBySku(sku);
}

const fetchProductsByTag = async (tag: TagDTO): Promise<ProductDTO[]> => {
    return await dal.fetchProductsByTag(tag);
}

const setProductXYZspecsToString = (dimensions: DimensionsDTO) => {
    return "Габариты, см - " + (dimensions.width ?? null) + " х " + + (dimensions.length ?? null) + " х " + (dimensions.height ?? null);
}

const sendTelegrammFastPurchaseMessage = (message: string) => {
    dal.sendTelegramBotFastPurchaseMessage(message);
}

const buildTelegrammFastPurchaseMessage = (phone: string, sku: string) => {
    return `Клиент хочет оформить быстрый заказ товара ${sku}, связаться с клиентом можно по телефону ${phone}.`;
}

const sendTelegrammSubscriberMessage = (message: string) => {
    dal.sendTelegramBotSubscriberMessage(message);
}

const buildTelegrammSubscriberMessage = (name:string,email:string, phone: string, sku: string) => {
    return `Клиент ${name} хочет получать информацию о наличии товара ${sku}, связаться с клиентом можно по телефону ${phone} или почте ${email}.`;
}

const sendTelegrammOrderMessage = (message: string) => {
    dal.sendTelegramBotOrderMessage(message);
}

const buildTelegrammOrderMessage = (name:string,email:string, phone: string, id: number, products: ShippingCartProduct[], cost:number, comment: string, payment: PaymentMethodEnum, adress: string) => {
    let itemsString:string = '';
    products.forEach(product => {itemsString += `, ${product.product.sku} в количестве ${product.quantity}шт.`});
    return `Клиент ${name} заказал группу товаров ${itemsString} на сумму ${cost} рублей - id заказа ${id}. Метод оплаты ${payment}, по адресу ${adress} Связаться с клиентом можно по телефону ${phone} или почте ${email}. Комментарий к заказу: ${comment}`;
}


const setBillingAdress = (name:string, email:string, phone: string, adress: string): BillingAdress => {
    return {first_name: name, address_1:adress, email, phone};
}

const setOrder = (billing: BillingAdress, payment_method: PaymentMethodEnum, set_paid: boolean, line_items: LineItem[] ): OrderDTO => {
    return {billing, payment_method, payment_method_title: payment_method, set_paid, line_items};
}

const sendOrder = async (order: OrderDTO):Promise<OrderResponseDTO> => {
    return await dal.setOrder(order);
}

const setAttributeUrlrequest = (attributes: AttributeFilterTerm[], attribute_slug: string): string => {
    if(attributes.length > 0) {
        let request:string = `&attribute=${attribute_slug}&attribute_term=`;
        /*let request:string = `&${attribute_slug}=`;*/
        attributes.forEach( (attribute, index) => {
            request = request + `${attribute.id}${index+1 == attributes.length ? `` : ','}`
        } );
        return request;
    } else {
        return '';
    }

}

const setBudgetUrlrequest = (attributes: [number,number]): string => {

    return `&min_price=${attributes[0]}&max_price=${attributes[1]}`;

}

const setCategoryUrlRequest = (categories: CategoryDTO[]): string => {
    if(categories.length > 0) {
        let request:string = `category=`;
        categories.forEach( (category, index) => {
            request = request + `${category.id}${index+1 == categories.length ? `` : ','}`
        } );
        return request;
    } else {
        return '';
    }
}

const setSearchUrlrequest = (search: string): string => {
    return search ? `&search=${search}` : ``;
}

const buildRequestFilterArguments = (categoryParams: string, argsParams: string, budgetParams: string, searchParams: string): string => {

    return categoryParams == '' ? argsParams.slice(1) + budgetParams + searchParams : categoryParams + argsParams + budgetParams + searchParams;
}

const fetchFilteredProducts = async (requestArgs: string, page: number): Promise<ProductDTO[]> => {
    return await dal.fetchFilteredProductsPerPage(requestArgs, page);
}

const fetchBanners = async (): Promise<BannerDTO[]> => {
    return dal.fetchBanners();
}

export const bl = {
    setSearchUrlrequest,
    fetchBanners,
    fetchFilteredProducts,
    fetchRecomendedProducts,
    setBudgetUrlrequest,
    buildRequestFilterArguments,
    setAttributeUrlrequest,
    setCategoryUrlRequest,
    sendTelegrammOrderMessage,
    buildTelegrammOrderMessage,
    sendOrder,
    setOrder,
    setBillingAdress,
    setProductToCartModel,
    sendTelegrammSubscriberMessage,
    buildTelegrammSubscriberMessage,
    sendTelegrammFastPurchaseMessage,
    buildTelegrammFastPurchaseMessage,
    fetchProductsByTag,
    fetchProductBySku,
    fetchTopProductsByOrder,
    isFilterIngredientsIncludesInProduct,
    convertOrderProductsToLineItems,
    filterProductsForTopSellers,
    isProductNew,
    setProductXYZspecsToString
}