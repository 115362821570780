import { createSelector } from "reselect";
import { RootState } from "../store";
import { CategoriesAdapter } from "./categories.slice";

const categoryState = (state:RootState) => state.categories;

const productState = (state:RootState) => state.products;

const categoriesEntitySelectors = CategoriesAdapter.getSelectors(categoryState);

const selectProductCategories = createSelector(categoriesEntitySelectors.selectAll, categories => categories.filter(category => category.id !== 15));

const selectProductParentCategories = createSelector(categoriesEntitySelectors.selectAll, categories => categories.filter(category => category.parent === 0 && category.id !== 15));

const selectProductChildrenCategories = createSelector(productState, categoriesEntitySelectors.selectAll, (state, categories) => categories.filter(category => category.parent !== 0 && category.id !== 15));

const selectLoadingStatus = createSelector(categoryState, state => state.loadingStatus);

export const categorySelectors = {
    selectProductCategories,
    selectProductParentCategories,
    selectProductChildrenCategories,
    selectLoadingStatus
}