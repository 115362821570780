import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProductDTO } from "../../models/types/product-dto.type";
import { bl } from "../../BL/BL";
import { FetchProductsPerPageByArgsCategoryDTO } from "../../models/types/products-thunk.type";

export const fetchProductsPerPageByArgumentsAndCategory = createAsyncThunk<ProductDTO[], FetchProductsPerPageByArgsCategoryDTO>(
    'products/getProductsByPageAndArgsAndCategoryList',
    async(args) => {
        const response:ProductDTO[] = await bl.fetchFilteredProducts(args.args, args.page);
        return response;
    }
)
