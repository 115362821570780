import { FC, useEffect, useState } from "react";
import "./Sales.scss"
import { BannerDTO } from "../../models/types/banner-dto.type";
import { bl } from "../../BL/BL";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store/store.hooks";
import { ProductsActions } from "../../store/products/products.slice";

export const Sales:FC = () => {
    const [banners, setbanners] = useState<BannerDTO[]>([]);
    useEffect(() => {
        bl.fetchBanners().then(bannersDto => setbanners(bannersDto));
    }, []);

    const dispatch = useAppDispatch();


    const setDefaultFilters = () => {
        dispatch(ProductsActions.setDefaultFilter());
        dispatch(ProductsActions.setReloadStatus(true));
    };
    
    return(
        <section className="sales__page-container">
            <h2 className="catalog__category-heading">
                <Link to="/" className="info__main-link">Главная</Link> - Акции
            </h2>
            <div className="sales__banner-container">
                {banners.map(banner => 
                    <div key={banner.id} className="sales__banner-element">
                        <img src={banner.mobileSrc} className="sales__banner-img sales__banner-img-mob" />
                        <div className="sales__banner-info" >   
                            <h4 className="sales__banner-title">
                                {banner.title}
                            </h4>
                            <p className="sales__banner-description" dangerouslySetInnerHTML={{__html: banner.description}} />
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}