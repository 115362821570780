import { bl } from "../../BL/BL";
import { ProductDTO } from "../types/product-dto.type"
import { ProductViewModel } from "../types/product-view-model.type"
import { Adapter } from "./interface/adapter.type"
import { stockStatusToBooleanAdapter } from "./stock-status-to-bool.adapter";

const convertForwardToBackwardEntity = (productDto: ProductDTO): ProductViewModel => {
    return {id: productDto.id, product: productDto, isTopSeller: false, isOnSale: productDto.on_sale, isNewProduct: bl.isProductNew(productDto.date_created), isAvailable:stockStatusToBooleanAdapter.convertForwardToBackwardEntity(productDto.stock_status)} as ProductViewModel;
}

export const productDtoToViewModeladapter:Adapter<ProductDTO, ProductViewModel> = {
    convertForwardToBackwardEntity
}