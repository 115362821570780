import { FC } from "react"
import { useSelector } from "react-redux";
import { ProductCard } from "./ProductCard/ProductCard";
import { productsSelectors } from "../../../store/products/products.selector";
import './List.scss';
import { LoadingStatus } from "../../../models/enums/loading-status.enum";
import ContentLoader from "react-content-loader";
import { Categories } from "../Categories/Categories";

export const List:FC = () => {
    const products = useSelector(productsSelectors.selectFilteredProducts);
    const productFetchingStatus = useSelector(productsSelectors.selectLoadingStatus);

    return(
        <section className="list__page-container">
            {/*<Categories />*/}
            <div className="list__container">
                {
                    products.map(product => <ProductCard key={product.id} {...product} />)
                }
            </div>

                {productFetchingStatus !== LoadingStatus.Fullfiled ? 
                    <ContentLoader
                    className="catalog__content-loader"
                    speed={1}
                    width={250}
                    height={400}
                    backgroundColor="#6b6b6b1c"
                    foregroundColor="#6b6b6b3b"
                    >
                        <circle cx="125" cy="110" r="80" />
                        <rect x="25" y="230" rx="8" ry="8" width="300" height="20" />
                        <rect x="25" y="270" rx="8" ry="8" width="100" height="25" />
                    </ContentLoader> : null

                }   
            {
                productFetchingStatus == LoadingStatus.Fullfiled && products.length == 0 ?
                <div className="list__empty">
                    Нет товаров согласно вашим запросам
                </div>: null
            }         
        </section>
    )
}