import { FC, useEffect, useState } from "react";
import RembayLogo from "../../local/png-webp/footer/logo.png";
import { ReactComponent as Instagramm } from "../../local/svg/footer/instagramm.svg";
import { isMobile } from "react-device-detect";
import './Footer.scss';
import { Link } from "react-router-dom";

export const Footer:FC = () => {
    const [clickedLinkStatus, setClickedLinkStatus] = useState<boolean>(false)
    useEffect(() => {
        if(clickedLinkStatus) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setClickedLinkStatus(false);
        }
    },[clickedLinkStatus]);

    return(
        <footer className={`footer footer__is-mobile-${isMobile}`}>
            <div className="footer__copyright-container">
                <div className="footer__logo-container">
                    <img src={RembayLogo} className="footer__logo-img"/>
                    <h1 className="footer__logo-head">
                            Интернет - магазин
                    </h1>
                </div>
                <div className="footer__credits-container">
                    <p className="footer__credits-info">© 2024 Рембай - интернет-магазин Общество с ограниченной ответственностью «Рембай Групп»</p>
                    <a href="https://smm-ibis.by/" target="_blank" className="footer__credits-link">Cайт разработан  IBIS marketing</a>
                </div>
            </div>

            <div className="footer__links-container">
                <Link onClick={() => setClickedLinkStatus(true)} to="/info" className="footer__links-element">Информация</Link>
                <Link onClick={() => setClickedLinkStatus(true)} to="/sales" className="footer__links-element">Акции</Link>

                <Link onClick={() => setClickedLinkStatus(true)} to="/info" className="footer__links-element">Доставка и оплата</Link>
                <Link onClick={() => setClickedLinkStatus(true)} to="/info" className="footer__links-element">Условия возврата</Link>
                <Link onClick={() => setClickedLinkStatus(true)} to="/info" className="footer__links-element">Контакты</Link>
                <Link onClick={() => setClickedLinkStatus(true)} to="/partners" className="footer__links-element">Оптовым клиентам</Link>
            </div>
            <div className="footer__adress-container">
                <p className="footer__adress-element">Адреса магазинов:</p>
                <p className="footer__adress-element">ул.Крыленко,9 телефон 8022 73-63-42 </p>
                <p className="footer__adress-element">ул.Белинского ,28 ТЦ “Гринвич”</p>
                <p className="footer__adress-element">ул. Островского,5 ТЦ “ Планета Green” телефон 8022 42-60-01</p>
            </div>
            <div className="footer__times-container">
                <p className="footer__times-element">График работы</p>
                <p className="footer__times-element">8:30 — 17:00</p>
                <p className="footer__times-element">сб, вс  — выходной</p>
            </div>
            <div className="footer__contacts-container">
                <a href="tel:+375447665050"  className="footer__contacts-element">
                    +375 44 766-50-50
                </a>
                <a href="mailto:buinichi18@mail.ru" className="footer__contacts-element">
                    buinichi18@mail.ru
                </a>
                <a className="footer__contacts-svg-container" href="https://www.instagram.com/rembay.by/" target="_blank" >
                    <Instagramm className="footer__contacts-svg" />
                </a>
            </div>
        </footer>
    );
}