import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchBanners } from "./banners.thunk";
import { LoadingStatus } from "../../models/enums/loading-status.enum";
import { BannerDTO } from "../../models/types/banner-dto.type";

export const BannersAdapter = createEntityAdapter<BannerDTO>();

export const BannersSlice = createSlice({
    name: 'Banners',
    initialState: BannersAdapter.getInitialState({
        loadingStatus: LoadingStatus.Idle,
    }),
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchBanners.fulfilled, (state, action: PayloadAction<BannerDTO[]>) => {
            BannersAdapter.setAll(state, action.payload);
            state.loadingStatus = LoadingStatus.Fullfiled;
        })
        .addCase(fetchBanners.rejected, (state) => {
            state.loadingStatus = LoadingStatus.Rejected;
        })
        .addCase(fetchBanners.pending, (state) => {
            state.loadingStatus = LoadingStatus.Pending;
        })
    }
});

export const BannersActions = BannersSlice.actions;
export default BannersSlice.reducer;