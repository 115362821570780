import { FC, useEffect, useState } from "react";
import { ShippingCartProduct } from "../../../models/types/shipping-cart-product.type";
import { ReactComponent as PlusSvg } from "../../../local/svg/product/plus.svg";
import { ReactComponent as MinusSvg } from "../../../local/svg/product/minus.svg";
import { ReactComponent as UnavailableSvg } from "../../../local/svg/product/unavailable.svg";
import { useAppDispatch } from "../../../store/store.hooks";
import { ShippingCartActions } from "../../../store/shipping-cart/shipping-cart.slice";
import './ShippingCartProductCard.scss';


export const ShippingCartProductCard:FC<ShippingCartProduct> = (props) => {
    const dispatch = useAppDispatch();

    const {id, product, quantity} = props;
    const [currentQuantity, setCurrentQuantity] = useState<number>(quantity);
    const [markAsDelete, setMarkAsDeleteStatus] = useState<boolean>(false);

    useEffect(() => {
        setCurrentQuantity(quantity);
    }, [quantity])

    const deleteShippingCartProduct = () => {
        dispatch(ShippingCartActions.removeProductfromCart({id, product, quantity: currentQuantity}));
    }

    const incrementProduct = (increment: boolean) => {
        if(currentQuantity == 1 && !increment) {
            setMarkAsDeleteStatus(true);
            //deleteShippingCartProduct();
        } else {
            increment ?  
                dispatch(ShippingCartActions.incrementProductfromCart({id, product, quantity: currentQuantity + 1})):
                dispatch(ShippingCartActions.incrementProductfromCart({id, product, quantity: currentQuantity - 1}));
        }
    }

    return(
        <section className="shipping-cart__container">
            {   markAsDelete ?
                <div className="shipping-cart__product-container">
                    <div className="shipping-cart__product-title-container">
                        <p className="shipping-cart__product-title-name">
                            Вы точно хотите удалить {product.name} из корзины?
                        </p>
                    </div>
                    <button className="shipping-cart__back" onClick={() => setMarkAsDeleteStatus(false)}>
                        вернуть
                    </button>
                    <button className="shipping-cart__close">
                        <UnavailableSvg className="shipping-cart__svg" onClick={() => deleteShippingCartProduct()}/>
                    </button>
                </div>:
                <div className="shipping-cart__product-container">
                    <div className="shipping-cart__product-img-container">
                        <img className="shipping-cart__product-img" src={product.images[0].src} />
                    </div>

                    <div className="shipping-cart__product-info-container">
                        <div className="shipping-cart__product-title-container">
                            <p className="shipping-cart__product-title-name">
                                {product.name}
                            </p>
                            {/*<p className="shipping-cart__product-title-sku">
                                Артикул:{product.sku}
                            </p>*/}
                        </div>

                        <div className="shipping-cart__product-quantity-container">
                            <p className="shipping-cart__product-quantity-heading">
                                количество
                            </p>
                            <div className="shipping-cart__product-controls-container">
                                <button className="shipping-cart__product-controls-element" onClick={() => incrementProduct(false)}><MinusSvg className="product__cart-product-controls-svg product__cart-product-controls-svg-minus"/></button>
                                    <p className="shipping-cart__product-controls-quantity">{quantity}</p>
                                <button className="shipping-cart__product-controls-element" onClick={() => incrementProduct(true)}><PlusSvg className="product__cart-product-controls-svg product__cart-product-controls-svg-plus" /></button>
                            </div>
                        </div>
                        <div className="shipping-cart__product-price-container">
                            {product.price} BYN/шт 
                        </div>
                    </div>
                    
                    <button className="shipping-cart__close">
                        <UnavailableSvg className="shipping-cart__svg" onClick={() => setMarkAsDeleteStatus(true)}/>
                    </button>
                </div>
            }
        </section>
    );
}